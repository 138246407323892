define("@fleetbase/frontend/routes/docs/guides/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DocsGuidesIndexRoute extends Ember.Route {}

  _exports.default = DocsGuidesIndexRoute;
});