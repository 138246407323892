define("@fleetbase/frontend/controllers/portal/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PortalHomeController = (_dec = Ember.inject.controller('portal'), _dec2 = Ember.computed.alias('portal.recentPosts'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class PortalHomeController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "portal", _descriptor, this);

      _initializerDefineProperty(this, "recentPosts", _descriptor2, this);

      _initializerDefineProperty(this, "featuresIndex", _descriptor3, this);

      _initializerDefineProperty(this, "features", _descriptor4, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "portal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "recentPosts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "featuresIndex", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        title: 'Custom service rates',
        icon: 'percentage',
        description: 'Create and assign custom service rate algorithims using variables such as distance, time, volume & weight. Calculate using per meter, fixed meter, custom or even cash on delivery.',
        image: '/images/features/custom-service-rates.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }, {
        title: 'Proof of delivery',
        icon: 'percentage',
        description: 'Electronic proof of delivery with built in signature and QR code scanning. Automatic order completion, notifcations and invoice generation.',
        image: '/images/features/proof-of-delivery.png',
        imageProps: {
          size: '100%',
          position: 'top left'
        }
      }, {
        title: 'Real-time ETA',
        icon: 'percentage',
        description: 'Give your customers updated and accurate ETAs on their deliveries and allow them to track using the Fleetbase Storefront open source app.',
        image: '/images/features/eta.png',
        imageProps: {
          size: '100%',
          position: 'center'
        }
      }, // {
      //     title: 'Real-time tracking & notifications',
      //     icon: 'percentage',
      //     description:
      //         'Track your drivers and deliveries in real time. Get notified when drivers complete specific activity status updates for delivery orders and create specific push notifications to keep your customers updated and informed.',
      //     image: '/images/features/custom-service-rates.png',
      //     imageProps: {
      //         size: 'auto',
      //         position: 'top center',
      //     },
      // },
      {
        title: 'Order scheduler',
        icon: 'percentage',
        description: 'Manage & easily schedule your orders to plan ahead for maximum efficiency. Create rules for orders to be scheduled or dispatched automatically to the nearest driver to the delivery pick up and drop off.',
        image: '/images/features/scheduler.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }, {
        title: 'Fleet management',
        icon: 'percentage',
        description: 'In-depth fleet and vehicle management. Manage all your drivers, vehicles & fleets and get real insights into your fleets sustainability and fuel emmisions.',
        image: '/images/features/fleet-management.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }, {
        title: 'Fuel reports',
        icon: 'percentage',
        description: 'Upload and track fuel reports for your fleets and vehicles. Integrate and track into our partners emissions and sustainability trackers.',
        image: '/images/features/fuel-reports.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }, // {
      //     title: 'Issues',
      //     icon: 'percentage',
      //     description: 'Extensive issue tracker to communicate and reolve issues in real time while deliveries are being carried out.',
      //     image: '/images/features/issues.png',
      //     imageProps: {
      //         size: 'auto',
      //         position: 'top left',
      //     },
      // },
      {
        title: 'Route optimization',
        icon: 'percentage',
        description: 'Built in route optimization engine to save your drivers time and fuel costs. Deliver goods to your customers faster and more efficiently.',
        image: '/images/features/route-optimization.png',
        imageProps: {
          size: 'auto',
          position: 'top center'
        }
      }, {
        title: 'Shipping/ packaging labels',
        icon: 'percentage',
        description: 'Generate and customize shipping and packaging lables on order creation. Integrate into leading warehouse management solutions for a quicker data transfer and a reduction in manual steps.',
        image: '/images/features/labels.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }, {
        title: 'Custom order flows',
        icon: 'percentage',
        description: 'Create custom order flow for different delivery types. Set your own delivery activity status updates and order criteria for a complete overview of all types of delivery and logistics processes.',
        image: '/images/features/order-configs.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }, {
        title: 'Ecommerce enabled',
        icon: 'percentage',
        description: 'Quickly build and manage your customer mobile and web ecommerce app for products and services using Fleetbase Storefront. Automatically integrated into Fleetbase operations systems and driver mobile apps, you can better manage your ecommerce customer orders, checkout and deliveries. Explore Storefront.',
        image: '/images/features/storefront.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }, {
        title: 'Developer friendly API',
        icon: 'percentage',
        description: 'Build, cutomize and integrate quickly with the Fleetbase API. View documentation.',
        image: '/images/features/developer-friendly.png',
        imageProps: {
          size: 'contain',
          position: 'top left'
        }
      }, {
        title: 'Extensions',
        icon: 'percentage',
        description: 'Add in or integrate third party features, systems and ad ons with Fleetbase Extensions. You can build directly into the Fleetbase console and offer your solution to exisiting Fleetbase users. Explore extensions.',
        image: '/images/features/extensions.png',
        imageProps: {
          size: 'auto',
          position: 'top left'
        }
      }];
    }
  })), _class));
  _exports.default = PortalHomeController;
});