define("@fleetbase/frontend/snippets/api/place/create-place", ["@fleetbase/sdk"], function (_sdk) {
  "use strict";

  // BEGIN-SNIPPET create-place
  const fleetbase = new _sdk.default('<api key>'); // using name, and location

  fleetbase.places.create({
    name: 'Space Needle',
    location: {
      type: 'Point',
      coordinates: [47.6204232, -122.34935530000001]
    }
  }).then(spaceNeedle => {
    console.log(spaceNeedle);
  }); // using address

  fleetbase.places.create({
    address: 'Space Needle, Seattle, WA'
  }).then(spaceNeedle => {
    console.log(spaceNeedle);
  }); // END-SNIPPET
});