define("@fleetbase/frontend/services/crud", ["exports", "@fleetbase/frontend/utils/get-model-name", "@fleetbase/frontend/utils/humanize"], function (_exports, _getModelName, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CrudService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class CrudService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "modalsManager", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    /**
     * Closes a current modal then opens another modal action
     *
     * @void
     */
    next() {
      const args = [...arguments];
      const nextAction = args[0]; // shift off the action

      args.shift();
      this.modalsManager.done().then(() => {
        if (typeof this[nextAction] === 'function') {
          this[nextAction](...args);
        }
      });
    }
    /**
     * Generic deletion modal with options
     *
     * @void
     */


    delete(model) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.modalsManager.confirm({
        title: `Are you sure to delete this ${(0, _humanize.default)((0, _getModelName.default)(model)).toLowerCase()}?`,
        confirm: modal => {
          modal.startLoading();
          model.destroyRecord().then(model => {
            this.notifications.success(`'${model.name}' has been deleted.`);
            modal.done();

            if (typeof options.onConfirm === 'function') {
              options.onConfirm(...arguments);
            }
          });
        },
        decline: modal => {
          modal.done().then(() => {
            if (typeof options.onDecline === 'function') {
              return options.onDecline(...arguments);
            }
          });
        },
        ...options
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "next", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = CrudService;
});