define("@fleetbase/frontend/utils/console-url", ["exports", "@fleetbase/frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = consoleUrl;

  const queryString = params => Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

  function consoleUrl() {
    let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let url = 'https://console.';
    let urlParams = !Ember.isBlank(queryParams) ? queryString(queryParams) : '';

    if (['qa', 'staging'].includes(_environment.default.environment)) {
      url += `${_environment.default.environment}.`;
    }

    if (['local', 'development'].includes(_environment.default.environment)) {
      url += 'fleetbase.dev';
    } else {
      url += 'fleetbase.io';
    }

    url += `/${path}`;

    if (urlParams) {
      url += `?${urlParams}`;
    }

    return url;
  }
});