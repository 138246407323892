define("@fleetbase/frontend/snippets/api/place/list-places", ["@fleetbase/sdk"], function (_sdk) {
  "use strict";

  // BEGIN-SNIPPET list-place
  const fleetbase = new _sdk.default('<api key>');
  fleetbase.places.findAll().then(places => {
    places.forEach(place => {
      console.log(place);
    });
  }); // END-SNIPPET
});