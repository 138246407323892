define("@fleetbase/frontend/transforms/point", ["exports", "@ember-data/serializer/transform", "terraformer"], function (_exports, _transform, _terraformer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PointTransform extends _transform.default {
    deserialize(serialized) {
      return new _terraformer.default.Point(serialized);
    }

    serialize(deserialized) {
      return new _terraformer.default.Point(deserialized);
    }

  }

  _exports.default = PointTransform;
});