define("@fleetbase/frontend/helpers/open-link", ["exports", "@fleetbase/frontend/utils/console-url"], function (_exports, _consoleUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function openLink(_ref) {
    let [url, target = '_blank'] = _ref;
    return function () {
      if (!url || typeof url !== 'string') {
        return;
      }

      if (url.startsWith('console:')) {
        url = (0, _consoleUrl.default)(url.replace('console:', ''));
      }

      window.open(url, target);
    };
  });

  _exports.default = _default;
});