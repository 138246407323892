define("@fleetbase/frontend/components/next/content-panel", ["exports", "@fleetbase/ui/components/next/content-panel"], function (_exports, _contentPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _contentPanel.default;
    }
  });
});