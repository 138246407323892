define("@fleetbase/frontend/routes/portal/landing-page/delivery-template", ["exports", "@fleetbase/frontend/utils/base-url"], function (_exports, _baseUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PortalLandingPageDeliveryTemplateRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _defineProperty(this, "contentMap", {
        'food-delivery2': {
          headerPreTitle: 'Food Delivery App',
          headerTitle: 'Food Delivery',
          headerTagline: 'Launch your own food delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage food delivery',
          questionText: 'Why have your own food delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own food delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Food Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Restaurants',
          clientText1: 'Whether you are a signular restaurant or restaurant chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Food Networks',
          clientText2: 'We allow networks of restaurants to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Cloud Kitchens',
          clientText3: 'Delivery directly from your cloud kitchen to your end customers'
        },
        'alcohol-delivery': {
          headerPreTitle: 'Alcohol Delivery App',
          headerTitle: 'Alcohol Delivery',
          headerTagline: 'Launch your own alcohol delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage alcohol delivery',
          questionText: 'Why have your own alcohol delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own alcohol delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and alcohol aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Alcohol Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their alcohol order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Alcohol Stores',
          clientText1: 'Whether you are a signular provider or chain of alcohol providers, you can provide a modern and intuative customer service',
          clientTitle2: 'Alcohol Networks',
          clientText2: 'We allow networks of alcohol providors to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Alcohol Distributors',
          clientText3: 'Delivery directly from your storage facilities to your end customers'
        },
        'ecommerce-delivery': {
          headerPreTitle: 'Ecommerce Delivery App',
          headerTitle: 'Ecommerce Delivery',
          headerTagline: 'Launch your own ecommerce delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage ecommerce delivery',
          questionText: 'Why have your own ecommerce delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own ecommerce delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and ecommerce delivery aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Ecommerce Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Ecommerce Companies',
          clientText1: 'Whether you are a vendor or a chain of multiple vendors, you can provide a modern and intuative customer service',
          clientTitle2: 'Ecommerce Networks',
          clientText2: 'We allow networks of ecommerce vendors to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Retailers & Individuals',
          clientText3: 'Delivery directly from your store or storage locations right  to your end customers'
        },
        'grocery-delivery': {
          headerPreTitle: 'Grocery Delivery App',
          headerTitle: 'Grocery Delivery',
          headerTagline: 'Launch your own grocery delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage grocery delivery',
          questionText: 'Why have your own grocery delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own grocery delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and grocery aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Grocery Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Grocery Stores & Supermarkets',
          clientText1: 'Whether you are a signular company or a grocery / supermarket chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Grocery / Supermarket Networks',
          clientText2: 'We allow networks of grocery stores to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Individual Grocery Stores',
          clientText3: 'Delivery directly from your grocery store to your end customers'
        },
        'laundry-delivery': {
          headerPreTitle: 'Laundry / Dry Cleaning Delivery App',
          headerTitle: 'Laundry / Dry Cleaning Delivery',
          headerTagline: 'Launch your own laundry or dry cleaning delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage laundry & dry cleaning delivery',
          questionText: 'Why have your own laundry or dry cleaning delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own laundry or dry cleaning delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and laundry aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Laundry & Dry Cleaning Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Laundry& Dry Cleaning Services',
          clientText1: 'Whether you are a signular company or laundry service chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Laundry Networks',
          clientText2: 'We allow networks of launderette to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Cloud Laundry Service',
          clientText3: 'Delivery directly from your cloud laundry service to your end customers'
        },
        'courier-delivery': {
          headerPreTitle: 'Courier Delivery App',
          headerTitle: 'Courier Delivery',
          headerTagline: 'Launch your own courier delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage courier delivery',
          questionText: 'Why have your own courier delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own courier delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Courier Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Courier Companies',
          clientText1: 'Whether you are a signular company or courier chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Courier Networks',
          clientText2: 'We allow networks of couriers to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Bespoke Couriers',
          clientText3: 'Couriers conducting besoke delivery services can use our delivery apps to increase their service and customer experiences'
        },
        'pizza-delivery': {
          headerPreTitle: 'Pizza Delivery App',
          headerTitle: 'Pizza Delivery',
          headerTagline: 'Launch your own pizza delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage pizza delivery',
          questionText: 'Why have your own pizza delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own pizza delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Pizza Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Pizza Restaurants',
          clientText1: 'Whether you are a signular company or pizza chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Pizza Networks',
          clientText2: 'We allow networks of pizza restaurants to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Cloud Pizza Kitchens',
          clientText3: 'Delivery directly from your cloud pizza kitchen to your end customers'
        },
        'document-delivery': {
          headerPreTitle: 'Document Delivery App',
          headerTitle: 'Document Delivery',
          headerTagline: 'Launch your own document delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage document delivery',
          questionText: 'Why have your own document delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own document delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Document Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Document Delivery Companies',
          clientText1: 'Whether you are a signular company or delivery chain, you can provide a modern and intuative customer service using our delivery apps',
          clientTitle2: 'Delivery Networks',
          clientText2: 'We allow networks of delivery comapnies to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Bespoke Document Delivery Companies',
          clientText3: 'Delivery companies conducting besoke services can use our delivery apps to increase their service and customer experiences'
        },
        'fuel-&-gas-delivery': {
          headerPreTitle: 'Fuel & Gas Delivery App',
          headerTitle: 'Fuel & Gas Delivery',
          headerTagline: 'Launch your own fuel & gas delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage fuel & gas delivery',
          questionText: 'Why have your own fuel & gas delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own fuel & gas delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Fuel & Gas Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Fuel & Gas Companies',
          clientText1: 'Whether you are a singular company or fuel & gas delivery chain, you can provide a modern and intuative customer service using our delivery apps',
          clientTitle2: 'Fuel & Gas Networks',
          clientText2: 'We allow networks of fuel & gas companies  to share resources and customers by utilizing one technology set up'
        },
        'ice-delivery': {
          headerPreTitle: 'Ice Delivery App',
          headerTitle: 'Ice Delivery',
          headerTagline: 'Launch your own ice delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage ice delivery',
          questionText: 'Why have your own ice delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own ice delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Ice Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Ice Delivery Companies',
          clientText1: 'Whether you are a signular company or ice delivery chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Ice Delivery Networks',
          clientText2: 'We allow networks of ice delivery companies to share resources and customers by utilizing one technology set up'
        },
        'pharmacy-medical-delivery': {
          headerPreTitle: 'Pharmacy & Medical Delivery App',
          headerTitle: 'Pharmacy & Medical Delivery',
          headerTagline: 'Launch your own pharmacy & medical delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage pharmacy & medical delivery',
          questionText: 'Why have your own pharmacy & medical delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own pharmacy & medical delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and medical aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Pharmacy & Medical Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Pharmacies & Medical Delivery',
          clientText1: 'Whether you are a signular pharmacy or  chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Pharmacy Networks',
          clientText2: 'We allow networks of pharmacies to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Cloud Pharmacies',
          clientText3: 'Delivery directly from your cloud pharmacy to your end customers'
        },
        'parcel-delivery': {
          headerPreTitle: 'Parcel Delivery App',
          headerTitle: 'Parcel Delivery',
          headerTagline: 'Launch your own parcel delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage parcel delivery',
          questionText: 'Why have your own parcel delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own parcel delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and parcel aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Parcel Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Parcel Delivery Companies',
          clientText1: 'Whether you are a signular or parcely delivery chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Parcel Delivery Networks',
          clientText2: 'We allow networks of parcel delivery companies to share resources and customers by utilizing one technology set up',
          clientTitle3: 'Bespoke Parcel Delivery Companies',
          clientText3: 'Delivery companies conducting besoke services can use our delivery apps to increase their service and customer experiences'
        },
        'hyper-local-delivery': {
          headerPreTitle: 'Hyper Local Delivery App',
          headerTitle: 'Hyper Local Delivery',
          headerTagline: 'Launch your own hyper local delivery app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage hyper local delivery',
          questionText: 'Why have your own hyper local delivery app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own hyper local delivery app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and hyper local aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Hyper Local Delivery',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your drivers',
          navigatorText: 'Mobile app for your drivers to accept, manage and carry out delivery orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Drivers can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient deliveries',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete delivery jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of delivery through QR code of electronic signature.',
          clientTitle1: 'Hyper Delivery Services',
          clientText1: 'Whether you are a signular or hyper delivery chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Hyber Delivery Networks',
          clientText2: 'We allow networks of hyper delivery companies to share resources and customers by utilizing one technology set up',
          clientTitle3: 'House Care & Personal Care Services',
          clientText3: 'Provide on demand service such as house care or personal care services by using our operations apps'
        },
        'aircon-service': {
          headerPreTitle: 'Aircon Service App',
          headerTitle: 'Aircon Service',
          headerTagline: 'Launch your own aircon service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage aircon service',
          questionText: 'Why have your own aircon service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own aircon service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and aircon aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Aircon Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Aircon Service Companies',
          clientText1: 'Whether you are a signular aircon service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Aircon Service Networks',
          clientText2: 'We allow networks of aircon service companies to share resources and customers by utilizing one technology set up'
        },
        'handyman-service': {
          headerPreTitle: 'Handyman Service App',
          headerTitle: 'Handyman Service',
          headerTagline: 'Launch your own Handyman Service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage Handyman Service',
          questionText: 'Why have your own Handyman Service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own Handyman Service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Handyman Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Handyman Service Companies',
          clientText1: 'Whether you are a signular handyman service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Handyman Service Networks',
          clientText2: 'We allow networks of aircon service companies to share resources and customers by utilizing one technology set up'
        },
        'cleaning-service': {
          headerPreTitle: 'Cleaning Service App',
          headerTitle: 'Cleaning Service',
          headerTagline: 'Launch your own Cleaning Service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage Cleaning Service',
          questionText: 'Why have your own Cleaning  Service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own Cleaning Service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Cleaning Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Cleaning Service Companies',
          clientText1: 'Whether you are a signular cleaning service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Cleaning Service Networks',
          clientText2: 'We allow networks of cleaning service companies to share resources and customers by utilizing one technology set up'
        },
        'transportation-service': {
          headerPreTitle: 'Transportation Service App',
          headerTitle: 'Transportation Service',
          headerTagline: 'Launch your own transportation service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage transportation service',
          questionText: 'Why have your own transportation service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own transportation service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and transportation aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Transportation Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Transportation Service Companies',
          clientText1: 'Whether you are a signular transportation service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Transportation Service Networks',
          clientText2: 'We allow networks of transportation service companies to share resources and customers by utilizing one technology set up'
        },
        'haulage-service': {
          headerPreTitle: 'Haulage Service App',
          headerTitle: 'Haulage Service',
          headerTagline: 'Launch your own haulage service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage haulage service',
          questionText: 'Why have your own haulage service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own haulage service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and haulage aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Haulage Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Haulage Service Companies',
          clientText1: 'Whether you are a signular haulage service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Haulage Service Networks',
          clientText2: 'We allow networks of haulage service companies to share resources and customers by utilizing one technology set up'
        },
        'logistics-service': {
          headerPreTitle: 'Logistics Service App',
          headerTitle: 'Logistics Service',
          headerTagline: 'Launch your own haulage service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage haulage service',
          questionText: 'Why have your own haulage service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own logistics service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and logistics aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Logistics Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Logistics Service Companies',
          clientText1: 'Whether you are a signular logistics service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Logistics Service Networks',
          clientText2: 'We allow networks of logistics service companies to share resources and customers by utilizing one technology set up',
          clientTitle3: 'All Type Of Logistics Services',
          clientText3: 'PRovide your customers with an app to request any type of transportation services, directly integrated into your delivery operations systems'
        },
        'tow-truck-service': {
          headerPreTitle: 'Tow Truck Service App',
          headerTitle: 'Tow Truck Service',
          headerTagline: 'Launch your own tow truck service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage tow truck service',
          questionText: 'Why have your own Tow Truck Service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own tow truck service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Tow Truck Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Tow Truck Service Companies',
          clientText1: 'Whether you are a signular tow truck service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Tow Truck Service Networks',
          clientText2: 'We allow networks of tow truck service companies to share resources and customers by utilizing one technology set up'
        },
        'breakdown-service': {
          headerPreTitle: 'Breakdown Service App',
          headerTitle: 'Breakdown Service',
          headerTagline: 'Launch your own breakdown service app or marketplace',
          mainButtonText: 'Get Started',
          mainButtonText2: 'Live Demo',
          mainTitle: 'Console to manage breakdown service',
          questionText: 'Why have your own breakdown service app?',
          answerText: 'Owning and managing your own software is key to scaling your business. Having your own breakdown service app and management increases your brand strength, reduces cost paid to 3rd party apps and allows you to take control of your technology',
          reasonTitle1: 'Increase your brand strength',
          reasonText1: 'Having your own mobile app for download increases your brand strength and recognition',
          reasonTitle2: 'Reduce cost',
          reasonText2: 'Reduce the amount you pay to third party apps and food aggregators. Take control of your operations.',
          reasonTitle3: 'Data insights and scale',
          reasonText3: 'With your own apps, you can get insights into your data and scale the technology the way you want to',
          firstImage: 'images/navigator-phone.png',
          customerApp: 'Customer App For Breakdown Service',
          customerAppText: 'Open source app for your customers to conduct orders, make payments, track and electronically confirm deliveries',
          titleFeature1: 'Easy Checkout',
          textFeature1: 'Customers can easily view, customise and check out their orders with an intuative user interface',
          titleFeature2: 'Multiple Payment Methods',
          textFeature2: 'Your customers can set multiple payment methods depending on their preffered choice. From credit card to cash on delivery',
          titleFeature3: 'Order Track and Trace',
          textFeature3: 'Your customers can view the status of their order and track the realtime delivery progress all within the app',
          storeTitle: 'Console for products and service management',
          storeText: 'Manage your entire store, products, payment methods and locations from the Storefront admin console',
          consoleTitle: 'Integration to delivery operations console',
          consoleText: 'A complete overview of your orders, drivers and delivery statuses, integrated into your driver and customer apps',
          navigatorTitle: 'Mobile app for your service staff',
          navigatorText: 'Mobile app for your service staff to accept, manage and carry out service orders.',
          driverReasonTitle1: 'Easy Setup',
          driverReasonText1: 'Service staff can simply login with their mobile number and get started',
          driverReasonTitle2: 'Route Optimization',
          driverReasonText2: 'Routes are optimized allowing for more efficient planning',
          driverReasonTitle3: 'Operational oversight',
          driverReasonText3: 'Drivers can intutivly accept, update and complete service jobs jobs.',
          driverReasonTitle4: 'Electronic POD',
          driverReasonText4: 'Proof of service completion through QR code of electronic signature.',
          clientTitle1: 'Breakdown Service Companies',
          clientText1: 'Whether you are a signular breakdown service company or chain, you can provide a modern and intuative customer service',
          clientTitle2: 'Breakdown Service Networks',
          clientText2: 'We allow networks of breakdown service companies to share resources and customers by utilizing one technology set up'
        }
      });
    }

    model(_ref) {
      let {
        slug
      } = _ref;
      return new Promise((resolve, reject) => {
        const content = this.contentMap[slug];

        if (content) {
          resolve(content);
        }

        reject(new Error('Content doesnt exist!'));
      }).catch(this.pageDoesNotExist.bind(this));
    }

    afterModel(model) {
      this.title = model.headerTitle;
      this.description = model.headerTagline;
      this.headTags = [// standard meta tags
      {
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-robots-tag',
        attrs: {
          name: 'robots',
          content: 'index, follow'
        }
      }, // opengraph meta tags - facebook
      {
        type: 'meta',
        tagId: 'meta-og-title-tag',
        attrs: {
          property: 'og:title',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description-tag',
        attrs: {
          property: 'og:description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-type-tag',
        attrs: {
          property: 'og:type',
          content: 'article'
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-url-tag',
        attrs: {
          property: 'og:url',
          content: (0, _baseUrl.default)('products/fleet-ops')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image-tag',
        attrs: {
          property: 'og:image',
          content: (0, _baseUrl.default)('images/fleetbase-console.png')
        }
      }, // twitter meta tags
      {
        type: 'meta',
        tagId: 'meta-twitter-card-tag',
        attrs: {
          name: 'twitter:card',
          content: 'summary'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-site-tag',
        attrs: {
          name: 'twitter:site',
          content: '@fleetbase_io'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-title-tag',
        attrs: {
          name: 'twitter:title',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-description-tag',
        attrs: {
          name: 'twitter:description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-image-src-tag',
        attrs: {
          name: 'twitter:image:src',
          content: (0, _baseUrl.default)('images/fleetbase-console.png')
        }
      }, // google+ meta tags
      {
        type: 'meta',
        tagId: 'meta-gplus-name-tag',
        attrs: {
          itemprop: 'name',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-gplus-description-tag',
        attrs: {
          itemprop: 'description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-gplus-image-tag',
        attrs: {
          itemprop: 'image',
          content: model.image_url
        }
      }];
    }

    pageDoesNotExist() {// do something later
    }

  }

  _exports.default = PortalLandingPageDeliveryTemplateRoute;
});