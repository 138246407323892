define("@fleetbase/frontend/snippets/api/place/update-place", ["@fleetbase/sdk"], function (_sdk) {
  "use strict";

  // BEGIN-SNIPPET update-place
  const fleetbase = new _sdk.default('<api key>');
  fleetbase.places.updateRecord('<place id>', {
    building: 'Seattle Space Needle'
  }).then(place => {
    console.log(place);
  }); // END-SNIPPET
});