define("@fleetbase/frontend/services/notifications", ["exports", "ember-cli-notifications/services/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationsService extends _notifications.default {
    /**
     * Handles errors from the server
     *
     * @param {Error} error
     * @void
     */
    serverError(error) {
      let fallbackMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Oops! Something went wrong with your request.';
      let options = arguments.length > 2 ? arguments[2] : undefined;

      // eslint-disable-next-line no-prototype-builtins
      if (typeof error === 'object' && error.hasOwnProperty('message')) {
        return this.error(error.message || fallbackMessage, options);
      }

      return this.error(fallbackMessage, options);
    }

  }

  _exports.default = NotificationsService;
});