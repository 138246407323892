define("@fleetbase/frontend/services/internal-data", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InternalData extends _store.default {
    adapterFor(model) {
      if (model === 'user') {
        return super.adapterFor('user');
      }

      return super.adapterFor('internal');
    }

  }

  _exports.default = InternalData;
});