define("@fleetbase/frontend/utils/get-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getInt;

  function getInt(subject, property) {
    return typeof subject === 'object' && Object.prototype.hasOwnProperty.call(subject, property) ? parseInt(subject[property]) : 0;
  }
});