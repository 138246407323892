define("@fleetbase/frontend/components/ui/page-loader", ["exports", "@fleetbase/ui/components/ui/page-loader"], function (_exports, _pageLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pageLoader.default;
    }
  });
});