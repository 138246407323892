define("@fleetbase/frontend/components/ui/modal", ["exports", "@fleetbase/ui/components/ui/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modal.default;
    }
  });
  throw new Error("`@fleetbase/frontend/components/ui/modal.hbs` does not contain a `default export`. Did you forget to export the component class?");
});