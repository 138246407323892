define("@fleetbase/frontend/components/next/map-container/toolbar", ["exports", "@fleetbase/ui/components/next/map-container/toolbar"], function (_exports, _toolbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _toolbar.default;
    }
  });
});