define("@fleetbase/frontend/serializers/order", ["exports", "@fleetbase/frontend/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import normalizeRelationsWithinHash from '../utils/serialize/normalize-relations-within-hash';
  class OrderSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        payload: {
          embedded: 'always'
        },
        driver_assigned: {
          embedded: 'always'
        },
        facilitator: {
          embedded: 'always'
        },
        customer: {
          embedded: 'always'
        },
        transaction: {
          embedded: 'always'
        },
        route: {
          embedded: 'always'
        },
        // tracking_number: { embedded: 'always' },
        tracking_statuses: {
          embedded: 'always'
        }
      };
    }
    /**
     * Attributes we want to remove when sending to server.
     *
     * @param {Snapshot} snapshot
     * @param {Object} json
     * @param {String} key
     * @param {Array} attributes
     */


    serializeAttribute(snapshot, json, key, attributes) {
      super.serializeAttribute(snapshot, json, key, attributes);
      const unshiftAttributes = ['driver_name', 'tracking', 'total_entities', 'transaction_amount', 'customer_name', 'facilitator_name', 'customer_is_vendor', 'customer_is_contact', 'pickup_name', 'dropoff_name', 'payload_id', 'driver_id', 'created_by_name', 'updated_by_name', 'purchase_rate_id'];

      if (unshiftAttributes.includes(key)) {
        delete json[key];
      }
    }

  }

  _exports.default = OrderSerializer;
});