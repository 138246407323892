define("@fleetbase/frontend/components/ui/dropdown-button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BasicDropdown
      ...attributes
      @renderInPlace={{@renderInPlace}}
      @registerAPI={{@registerAPI}}
      @horizontalPosition={{@horizontalPosition}}
      @verticalPosition={{@verticalPosition}}
      @calculatePosition={{@calculatePosition}}
      @onOpen={{@onOpen}}
      @onClose={{@onClose}} as |dd|
  >
      <dd.Trigger class={{@triggerClass}}>
          <Ui::Button title={{@text}} class={{@buttonClass}} @type={{this.type}} @active={{@active}} @size={{this.buttonSize}} @isLoading={{@isLoading}} disabled={{@disabled}}>
              {{#if @icon}}
                  <FaIcon @icon={{@icon}} class={{@iconClass}} />
              {{/if}}
  
              {{#if @img}}
                  <img src={{@img}} class={{@imgClass}} alt={{or @alt "image"}} />
              {{/if}}
              
              <span class={{@textClass}}>
                  {{@text}}
              </span>
          </Ui::Button>
      </dd.Trigger>
      <dd.Content class={{@contentClass}} @overlay={{@overlay}}>
          {{yield dd}}
      </dd.Content>
  </BasicDropdown>
  */
  {
    "id": "2sxlvFG9",
    "block": "[[[8,[39,0],[[17,1]],[[\"@renderInPlace\",\"@registerAPI\",\"@horizontalPosition\",\"@verticalPosition\",\"@calculatePosition\",\"@onOpen\",\"@onClose\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,9,[\"Trigger\"]],[[16,0,[30,10]]],null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],[[16,\"title\",[30,11]],[16,0,[30,12]],[16,\"disabled\",[30,13]]],[[\"@type\",\"@active\",\"@size\",\"@isLoading\"],[[30,0,[\"type\"]],[30,14],[30,0,[\"buttonSize\"]],[30,15]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,16],[[[1,\"                \"],[8,[39,3],[[16,0,[30,17]]],[[\"@icon\"],[[30,16]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,18],[[[1,\"                \"],[10,\"img\"],[15,\"src\",[30,18]],[15,0,[30,19]],[15,\"alt\",[28,[37,4],[[30,20],\"image\"],null]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"            \\n            \"],[10,1],[15,0,[30,21]],[12],[1,\"\\n                \"],[1,[30,11]],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,9,[\"Content\"]],[[16,0,[30,22]]],[[\"@overlay\"],[[30,23]]],[[\"default\"],[[[[1,\"\\n        \"],[18,24,[[30,9]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[9]]]]]],[\"&attrs\",\"@renderInPlace\",\"@registerAPI\",\"@horizontalPosition\",\"@verticalPosition\",\"@calculatePosition\",\"@onOpen\",\"@onClose\",\"dd\",\"@triggerClass\",\"@text\",\"@buttonClass\",\"@disabled\",\"@active\",\"@isLoading\",\"@icon\",\"@iconClass\",\"@img\",\"@imgClass\",\"@alt\",\"@textClass\",\"@contentClass\",\"@overlay\",\"&default\"],false,[\"basic-dropdown\",\"ui/button\",\"if\",\"fa-icon\",\"or\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/ui/dropdown-button.hbs",
    "isStrictMode": false
  });

  let UiDropdownButtonComponent = (_dec = Ember._action, _dec2 = Ember.computed('args.type'), _dec3 = Ember.computed('args.size'), (_class = class UiDropdownButtonComponent extends _component.default {
    /**
     * Forwards an action to component owner
     *
     * @void
     */
    forwardAction(_action) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let dd = arguments.length > 2 ? arguments[2] : undefined;
      Ember.tryInvoke(dd.actions, 'close');

      if (typeof this.args[`on${Ember.String.classify(_action)}`] === 'function') {
        this.args[`on${Ember.String.classify(_action)}`](...params);
      } else if (typeof this.args.onAction === 'function') {
        this.args.onAction(_action, ...params);
      }
    }
    /**
     * Default button type
     *
     * @var {String}
     */


    get type() {
      return this.args.type || 'default';
    }
    /**
     * Default button size
     *
     * @var {String}
     */


    get buttonSize() {
      return this.args.size || 'md';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "forwardAction", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "forwardAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "type", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "type"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttonSize", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "buttonSize"), _class.prototype)), _class));
  _exports.default = UiDropdownButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiDropdownButtonComponent);
});