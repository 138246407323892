define("@fleetbase/frontend/controllers/portal/blog/post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PortalBlogPostController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class PortalBlogPostController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "comments", _descriptor, this);

      _initializerDefineProperty(this, "isCommenting", _descriptor2, this);

      _initializerDefineProperty(this, "isPostingComment", _descriptor3, this);

      _initializerDefineProperty(this, "isPostingReply", _descriptor4, this);

      _initializerDefineProperty(this, "draftComment", _descriptor5, this);

      _initializerDefineProperty(this, "draftReply", _descriptor6, this);
    }

    cancelComment() {
      this.draftComment = null;
    }

    startReply(parentComment) {
      const reply = this.store.createRecord('comment', {
        post_uuid: this.model.id,
        reply_to_uuid: parentComment.id
      });
      this.draftReply = reply;
    }

    startNewComment() {
      const comment = this.store.createRecord('comment', {
        post_uuid: this.model.id
      });
      this.draftComment = comment;
    }

    postComment() {
      const comment = this.draftComment;
      this.isPostingComment = true;
      comment.save().then(comment => {
        this.isPostingComment = false;
        this.model.comments.pushObject(comment);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "comments", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isCommenting", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPostingComment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isPostingReply", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "draftComment", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "draftReply", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancelComment", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cancelComment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startReply", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "startReply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startNewComment", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "startNewComment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postComment", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "postComment"), _class.prototype)), _class));
  _exports.default = PortalBlogPostController;
});