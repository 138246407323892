define("@fleetbase/frontend/components/sdk-code-snippet", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="code-snippet-toggle border border-gray-700 rounded-md shadow-sm overflow-hidden" ...attributes>
      <div class="header rounded-t-md bg-gray-700 flex items-center justify-between py-2 px-4">
          <div class="text-sm font-mono {{@titleClass}}">
              {{@title}}
          </div>
          <div class="flex items-center">
              <Ui::Select class="h-auto py-0" @value={{this.lang}} @options={{this.languages}} @optionLabel="name" @optionValue="value" @onSelect={{fn this.onChangeLanguage}} {{on "change" (fn this.watchChange)}} />
          </div>
      </div>
      <div class="body bg-gray-700 rounded-b-md">
          {{#let (get-code-snippet this.snippetName) as |snippet|}}
              <CodeBlock @code={{snippet.source}} @language={{this.lang}} />
          {{/let}}
      </div>
  </div>
  */
  {
    "id": "eEaMjl/8",
    "block": "[[[11,0],[24,0,\"code-snippet-toggle border border-gray-700 rounded-md shadow-sm overflow-hidden\"],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"header rounded-t-md bg-gray-700 flex items-center justify-between py-2 px-4\"],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"text-sm font-mono \",[30,2]]]],[12],[1,\"\\n            \"],[1,[30,3]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n            \"],[8,[39,0],[[24,0,\"h-auto py-0\"],[4,[38,2],[\"change\",[28,[37,1],[[30,0,[\"watchChange\"]]],null]],null]],[[\"@value\",\"@options\",\"@optionLabel\",\"@optionValue\",\"@onSelect\"],[[30,0,[\"lang\"]],[30,0,[\"languages\"]],\"name\",\"value\",[28,[37,1],[[30,0,[\"onChangeLanguage\"]]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"body bg-gray-700 rounded-b-md\"],[12],[1,\"\\n\"],[44,[[28,[37,4],[[30,0,[\"snippetName\"]]],null]],[[[1,\"            \"],[8,[39,5],null,[[\"@code\",\"@language\"],[[30,4,[\"source\"]],[30,0,[\"lang\"]]]],null],[1,\"\\n\"]],[4]]],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@titleClass\",\"@title\",\"snippet\"],false,[\"ui/select\",\"fn\",\"on\",\"let\",\"get-code-snippet\",\"code-block\"]]",
    "moduleName": "@fleetbase/frontend/components/sdk-code-snippet.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-computed-properties-in-native-classes */


  let SdkCodeSnippetComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.computed('args.lang', '_lang'), _dec4 = Ember.computed('args.name', 'lang'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class SdkCodeSnippetComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "languages", _descriptor, this);

      _initializerDefineProperty(this, "_lang", _descriptor2, this);
    }

    get lang() {
      if (this._lang) {
        return this._lang;
      }

      if (this.args.lang) {
        return this.args.lang;
      }

      return 'bash';
    }

    set lang(lang) {
      this._lang = lang;
    }

    get snippetName() {
      return `${this.args.name}.${this.lang}`;
    }

    onChangeLanguage(selected) {
      Ember.set(this, 'lang', selected);

      if (typeof this.args.onLanguageChange === 'function') {
        this.args.onLanguageChange(selected);
      }
    }

    watchChange(event) {
      var _event$target;

      this.onChangeLanguage(event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.value);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "languages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        name: 'Bash',
        value: 'bash'
      }, {
        name: 'Javascript',
        value: 'js'
      }, {
        name: 'PHP',
        value: 'php'
      }];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_lang", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lang", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "lang"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "snippetName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "snippetName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeLanguage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeLanguage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "watchChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "watchChange"), _class.prototype)), _class));
  _exports.default = SdkCodeSnippetComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SdkCodeSnippetComponent);
});