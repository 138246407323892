define("@fleetbase/frontend/components/morph-nav/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="{{if (has-block) 'has-dropdown'}} {{@navItemClass}}" data-content={{@id}} ...attributes>
      <LinkTo @route={{@route}} class={{@linkClass}}>
          {{@title}}
      </LinkTo>
      <EmberWormhole @to="morphNavContentContainer">
          <li id={{@id}} class="dropdown {{@contentClass}}">
              <a href="javascript:;" class="label {{@titleClass}}">
                  {{@title}}
              </a>
              <div class="content">
                  {{yield}}
              </div>
          </li>
      </EmberWormhole>
  </li>
  
  */
  {
    "id": "o4sWgWkm",
    "block": "[[[11,\"li\"],[16,0,[29,[[52,[48,[30,9]],\"has-dropdown\",null],\" \",[30,1]]]],[16,\"data-content\",[30,2]],[17,3],[12],[1,\"\\n    \"],[8,[39,2],[[16,0,[30,4]]],[[\"@route\"],[[30,5]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,6]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,3],null,[[\"@to\"],[\"morphNavContentContainer\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"li\"],[15,1,[30,2]],[15,0,[29,[\"dropdown \",[30,7]]]],[12],[1,\"\\n            \"],[10,3],[14,6,\"javascript:;\"],[15,0,[29,[\"label \",[30,8]]]],[12],[1,\"\\n                \"],[1,[30,6]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n                \"],[18,9,null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@navItemClass\",\"@id\",\"&attrs\",\"@linkClass\",\"@route\",\"@title\",\"@contentClass\",\"@titleClass\",\"&default\"],false,[\"if\",\"has-block\",\"link-to\",\"ember-wormhole\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/morph-nav/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});