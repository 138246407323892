define("@fleetbase/frontend/utils/pagination/truncate-pages", ["exports", "@fleetbase/frontend/utils/get-int"], function (_exports, _getInt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PaginationTruncatePages = (_dec = Ember.computed('currentPage', 'numPagesToShow', 'showFL', 'totalPages'), (_class = class PaginationTruncatePages extends Ember.Object {
    /**
     * Checks if given page is valid or matches with meta
     *
     * @param {Integer} page
     * @return {Boolean}
     */
    isValidPage(page) {
      page = parseInt(page);
      return page > 0 && page <= parseInt(this.totalPages);
    }
    /**
     * An array of pages to display at a time
     *
     * @var {Array}
     */


    get pagesToShow() {
      var res = [];
      var numPagesToShow = (0, _getInt.default)(this, 'numPagesToShow');
      var currentPage = (0, _getInt.default)(this, 'currentPage');
      var totalPages = (0, _getInt.default)(this, 'totalPages');
      var showFL = this.showFL;
      var before = parseInt(numPagesToShow / 2);

      if (currentPage - before < 1) {
        before = currentPage - 1;
      }

      var after = numPagesToShow - before - 1;

      if (totalPages - currentPage < after) {
        after = totalPages - currentPage;
        before = numPagesToShow - after - 1;
      } // add one page if no first or last is added


      if (showFL) {
        if (currentPage - before < 2) {
          after++;
        }

        if (totalPages - currentPage - 1 < after) {
          before++;
        }
      } // add each prior page


      for (var i = before; i > 0; i--) {
        var possiblePage = currentPage - i;

        if (this.isValidPage(possiblePage)) {
          res.push(possiblePage);
        }
      }

      res.push(currentPage); // add each following page

      for (i = 1; i <= after; i++) {
        var possiblePage2 = currentPage + i;

        if (this.isValidPage(possiblePage2)) {
          res.push(possiblePage2);
        }
      } // add first and last page


      if (showFL) {
        if (res.length > 0) {
          // add first page if not already there
          if (res[0] !== 1) {
            res = [1].concat(res);
          } // add last page if not already there


          if (res[res.length - 1] !== totalPages) {
            res.push(totalPages);
          }
        }
      }

      return Ember.A(res);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "pagesToShow", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pagesToShow"), _class.prototype)), _class));
  _exports.default = PaginationTruncatePages;
});