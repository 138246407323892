define("@fleetbase/frontend/serializers/application", ["exports", "@ember-data/serializer/rest", "@fleetbase/frontend/utils/is-model"], function (_exports, _rest, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApplicationSerializer extends _rest.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "primaryKey", 'uuid');
    }

    /**
     * Customize serializer so that any attributes that are instances of Models or objects
     * that are to accept and ID get serialized into the id only
     *
     * @param {Snapshot} snapshot
     * @param {Object} options
     * @return {Object} json
     */
    serialize(snapshot) {
      const json = super.serialize(...arguments);
      const attributes = { ...snapshot.attributes()
      };

      for (let property in attributes) {
        if (property.includes('uuid') && typeof attributes[property] === 'object' && !Ember.isEmpty(attributes[property])) {
          if ((0, _isModel.default)(attributes[property])) {
            json[property] = attributes[property].id;
          } else {
            json[property] = attributes[property].uuid || attributes[property].id;
          }
        } else if (!Ember.isEmpty(attributes[property])) {
          json[property] = attributes[property];
        }
      }

      return json;
    }
    /**
     * We only want to save dirty/changed model attributes
     *
     * @param {Snapshot} snapshot
     * @param {Object} json
     * @param {String} key
     * @param {Array} attributes
     */


    serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        return super.serializeAttribute(snapshot, json, key, attributes);
      }
    }
    /**
     * Helper function to check if relationship set on model matches the id attribute
     *
     * @param {Object} json
     * @param {Model|Object} relation
     * @param {String} id
     */


    relationMatchesId(json, relation, id) {
      return typeof json[relation] === 'object' && !Ember.isEmpty(json[relation]) && Ember.get(json, `${relation}.uuid`) === Ember.get(json, id);
    }

  }

  _exports.default = ApplicationSerializer;
});