define("@fleetbase/frontend/modifiers/inline-style", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function inlineStyle(element, _ref) {
    let [styles] = _ref;
    const keys = Object.keys(styles);

    for (let index = 0; index < keys.length; index++) {
      const key = keys.objectAt(index);
      element.style[Ember.String.camelize(key)] = styles[key];
    }
  });

  _exports.default = _default;
});