define("@fleetbase/frontend/components/ui/button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="btn-wrapper inline-flex rounded-md {{if this.isNotSecondary "shadow-sm"}} {{if @isLoading "is-loading"}} {{@wrapperClass}}">
      <BsButton
          class="{{@class}} {{if @isLoading "btn-is-loading"}}"
          @buttonType={{@buttonType}}
          @type={{@type}}
          @outline={{@outline}}
          @size={{@size}}
          @active={{@active}}
          @onClick={{@onClick}}
          disabled={{this.isDisabled}}
          ...attributes
      >
          {{#if @isLoading}}
              <span class="btn-icon-wrapper btn-loading-icon-wrapper" data-icon-prefix={{@iconPrefix}}>
                  <FaIcon @prefix={{@iconPrefix}} @icon="spinner-third" @spin={{true}} class="mr-2" />
              </span>
          {{/if}}
          {{#if this.displayIcon}}
              <span class="btn-icon-wrapper">
                  <FaIcon @icon={{@icon}} @size={{@iconSize}} @rotation={{@iconRotation}} @flip={{@iconFlip}} @spin={{@iconSpin}} class="mr-2 {{@iconClass}}" />
              </span>
          {{/if}}
          <span class={{@textClass}}>
              {{@text}}
          </span>
          {{yield}}
      </BsButton>
  </span>
  */
  {
    "id": "Vvh/6IQT",
    "block": "[[[10,1],[15,0,[29,[\"btn-wrapper inline-flex rounded-md \",[52,[30,0,[\"isNotSecondary\"]],\"shadow-sm\",null],\" \",[52,[30,1],\"is-loading\",null],\" \",[30,2]]]],[12],[1,\"\\n    \"],[8,[39,1],[[16,0,[29,[[30,3],\" \",[52,[30,1],\"btn-is-loading\",null]]]],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[17,4]],[[\"@buttonType\",\"@type\",\"@outline\",\"@size\",\"@active\",\"@onClick\"],[[30,5],[30,6],[30,7],[30,8],[30,9],[30,10]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[10,1],[14,0,\"btn-icon-wrapper btn-loading-icon-wrapper\"],[15,\"data-icon-prefix\",[30,11]],[12],[1,\"\\n                \"],[8,[39,2],[[24,0,\"mr-2\"]],[[\"@prefix\",\"@icon\",\"@spin\"],[[30,11],\"spinner-third\",true]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"displayIcon\"]],[[[1,\"            \"],[10,1],[14,0,\"btn-icon-wrapper\"],[12],[1,\"\\n                \"],[8,[39,2],[[16,0,[29,[\"mr-2 \",[30,12]]]]],[[\"@icon\",\"@size\",\"@rotation\",\"@flip\",\"@spin\"],[[30,13],[30,14],[30,15],[30,16],[30,17]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,1],[15,0,[30,18]],[12],[1,\"\\n            \"],[1,[30,19]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[18,20,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13]],[\"@isLoading\",\"@wrapperClass\",\"@class\",\"&attrs\",\"@buttonType\",\"@type\",\"@outline\",\"@size\",\"@active\",\"@onClick\",\"@iconPrefix\",\"@iconClass\",\"@icon\",\"@iconSize\",\"@iconRotation\",\"@iconFlip\",\"@iconSpin\",\"@textClass\",\"@text\",\"&default\"],false,[\"if\",\"bs-button\",\"fa-icon\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/ui/button.hbs",
    "isStrictMode": false
  });

  let UiButtonComponent = (_dec = Ember.computed('args.{isLoading,disabled}'), _dec2 = Ember.computed.equal('args.type', 'secondary'), _dec3 = Ember.computed.not('isSecondary'), _dec4 = Ember.computed.not('args.isLoading'), _dec5 = Ember.computed('args.{icon,isLoading}', 'isNotLoading'), (_class = class UiButtonComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isSecondary", _descriptor, this);

      _initializerDefineProperty(this, "isNotSecondary", _descriptor2, this);

      _initializerDefineProperty(this, "isNotLoading", _descriptor3, this);
    }

    /**
     * Determines if the button should be disabled
     *
     * @var {Boolean}
     */
    get isDisabled() {
      return this.args.isLoading || this.args.disabled;
    }
    /**
     * Determines if the button should be disabled
     *
     * @var {Boolean}
     */


    /**
     * Determines if icon be displayed
     *
     * @var {Boolean}
     */
    get displayIcon() {
      return this.args.icon && this.isNotLoading;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isDisabled"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isSecondary", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isNotSecondary", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isNotLoading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "displayIcon", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "displayIcon"), _class.prototype)), _class));
  _exports.default = UiButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiButtonComponent);
});