define("@fleetbase/frontend/utils/array-to-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arrayToObject;

  function arrayToObject() {
    let arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    if (!Ember.isArray(arr)) {
      return {};
    }

    const obj = arr.reduce(function (acc, cur) {
      acc[cur] = null;
      return acc;
    }, {});
    return { ...obj
    };
  }
});