define("@fleetbase/frontend/models/place", ["exports", "@ember-data/model", "@fleetbase/frontend/utils/is-valid-coordinates", "@fleetbase/frontend/utils/is-latitude", "@fleetbase/frontend/utils/is-longitude", "moment"], function (_exports, _model, _isValidCoordinates, _isLatitude, _isLongitude, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Place = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.belongsTo)('vendor'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('point'), _dec24 = (0, _model.attr)('raw'), _dec25 = (0, _model.attr)('date'), _dec26 = (0, _model.attr)('date'), _dec27 = (0, _model.attr)('date'), _dec28 = Ember.computed('location'), _dec29 = Ember.computed('location'), _dec30 = Ember.computed('latitude', 'longitude'), _dec31 = Ember.computed('latitude', 'longitude'), _dec32 = Ember.computed('latitude', 'longitude'), _dec33 = Ember.computed('coordinates'), _dec34 = Ember.computed.not('hasValidCoordinates'), _dec35 = Ember.computed('updated_at'), _dec36 = Ember.computed('updated_at'), _dec37 = Ember.computed('created_at'), _dec38 = Ember.computed('created_at'), (_class = class Place extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uuid", _descriptor, this);

      _initializerDefineProperty(this, "public_id", _descriptor2, this);

      _initializerDefineProperty(this, "company_uuid", _descriptor3, this);

      _initializerDefineProperty(this, "vendor_uuid", _descriptor4, this);

      _initializerDefineProperty(this, "vendor", _descriptor5, this);

      _initializerDefineProperty(this, "name", _descriptor6, this);

      _initializerDefineProperty(this, "phone", _descriptor7, this);

      _initializerDefineProperty(this, "type", _descriptor8, this);

      _initializerDefineProperty(this, "address", _descriptor9, this);

      _initializerDefineProperty(this, "street1", _descriptor10, this);

      _initializerDefineProperty(this, "street2", _descriptor11, this);

      _initializerDefineProperty(this, "city", _descriptor12, this);

      _initializerDefineProperty(this, "province", _descriptor13, this);

      _initializerDefineProperty(this, "postal_code", _descriptor14, this);

      _initializerDefineProperty(this, "neighborhood", _descriptor15, this);

      _initializerDefineProperty(this, "district", _descriptor16, this);

      _initializerDefineProperty(this, "building", _descriptor17, this);

      _initializerDefineProperty(this, "security_access_code", _descriptor18, this);

      _initializerDefineProperty(this, "country", _descriptor19, this);

      _initializerDefineProperty(this, "country_name", _descriptor20, this);

      _initializerDefineProperty(this, "vendor_name", _descriptor21, this);

      _initializerDefineProperty(this, "_import_id", _descriptor22, this);

      _initializerDefineProperty(this, "location", _descriptor23, this);

      _initializerDefineProperty(this, "meta", _descriptor24, this);

      _initializerDefineProperty(this, "deleted_at", _descriptor25, this);

      _initializerDefineProperty(this, "created_at", _descriptor26, this);

      _initializerDefineProperty(this, "updated_at", _descriptor27, this);

      _initializerDefineProperty(this, "hasInvalidCoordinates", _descriptor28, this);
    }

    toJson() {
      return this.serialize();
    }

    get latitude() {
      if (this.location) {
        let x = Ember.get(this.location, 'coordinates.0');
        let y = Ember.get(this.location, 'coordinates.1');
        return (0, _isLatitude.default)(x) ? x : y;
      }

      return 0;
    }

    get longitude() {
      if (this.location) {
        let x = Ember.get(this.location, 'coordinates.0');
        let y = Ember.get(this.location, 'coordinates.1');
        return (0, _isLongitude.default)(y) ? y : x;
      }

      return 0;
    }

    get coordinates() {
      // eslint-disable-next-line ember/no-get
      return [Ember.get(this, 'latitude'), Ember.get(this, 'longitude')];
    }

    get latlng() {
      return {
        // eslint-disable-next-line ember/no-get
        lat: Ember.get(this, 'latitude'),
        // eslint-disable-next-line ember/no-get
        lng: Ember.get(this, 'longitude')
      };
    }

    get latitudelongitude() {
      return {
        // eslint-disable-next-line ember/no-get
        latitude: Ember.get(this, 'latitude'),
        // eslint-disable-next-line ember/no-get
        longitude: Ember.get(this, 'longitude')
      };
    }

    get hasValidCoordinates() {
      return (0, _isValidCoordinates.default)(this.coordinates);
    }

    get updatedAgo() {
      return (0, _moment.default)(this.updated_at).fromNow();
    }

    get updatedAt() {
      return (0, _moment.default)(this.updated_at).format('DD MMM YYYY');
    }

    get createdAgo() {
      return (0, _moment.default)(this.created_at).fromNow();
    }

    get createdAt() {
      return (0, _moment.default)(this.created_at).format('DD MMM YYYY');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "vendor_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "street1", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "street2", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "province", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "postal_code", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "neighborhood", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "district", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "building", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "security_access_code", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "country_name", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "vendor_name", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "_import_id", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "meta", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "latitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "longitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "coordinates", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "coordinates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "latlng", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "latlng"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "latitudelongitude", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "latitudelongitude"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasValidCoordinates", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "hasValidCoordinates"), _class.prototype), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "hasInvalidCoordinates", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype)), _class));
  _exports.default = Place;
});