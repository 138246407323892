define("@fleetbase/frontend/services/current-user", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrentUserService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberLocalStorage.storageFor)('user-options'), _dec6 = Ember.computed.alias('user.id'), _dec7 = Ember.computed.alias('user.name'), _dec8 = Ember.computed.alias('user.company_uuid'), _dec9 = Ember.computed('id'), (_class = class CurrentUserService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "theme", _descriptor3, this);

      _initializerDefineProperty(this, "internalData", _descriptor4, this);

      _initializerDefineProperty(this, "options", _descriptor5, this);

      _initializerDefineProperty(this, "id", _descriptor6, this);

      _initializerDefineProperty(this, "name", _descriptor7, this);

      _initializerDefineProperty(this, "companyId", _descriptor8, this);
    }

    /**
     * Loads the current authenticated user
     *
     * @void
     */
    async load() {
      if (this.session.isAuthenticated) {
        let user = await this.internalData.queryRecord('user', {
          me: true
        });
        this.set('user', user);
      }
    }
    /**
     * Resolves a user model.
     *
     * @return {Promise}
     */


    promiseUser() {
      const NoUserAuthenticatedError = new Error('Failed to authenticate user.');
      return new Promise((resolve, reject) => {
        if (this.session.isAuthenticated) {
          return this.internalData.queryRecord('user', {
            me: true
          }).then(user => {
            this.set('user', user); // this.theme.setEnvironment(); // set environment from user option

            resolve(user);
          }).catch(() => {
            reject(NoUserAuthenticatedError);
          });
        }

        reject(NoUserAuthenticatedError);
      });
    }

    setUserTo(target) {
      Ember.set(target, 'user', this.user);
    }
    /**
     * The prefix for this user options
     *
     * @var {String}
     */


    get optionsPrefix() {
      return `${this.id}:`;
    }
    /**
     * Sets a user's option in local storage
     *
     * @param {String} key
     * @param {Mixed} value
     * @return {CurrentUserService}
     */


    setOption(key, value) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      this.options.set(key, value);
      return this;
    }
    /**
     * Retrieves a user option from local storage
     *
     * @param {String} key
     * @return {Mixed}
     */


    getOption(key) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      return this.options.get(key);
    }
    /**
     * Checks if an option exists in users local storage
     *
     * @param {String} key
     * @return {Boolean}
     */


    hasOption(key) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      return this.getOption(key) !== undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "internalData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "id", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "companyId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "optionsPrefix", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "optionsPrefix"), _class.prototype)), _class));
  _exports.default = CurrentUserService;
});