define("@fleetbase/frontend/utils/is-array-of-ids", ["exports", "@fleetbase/frontend/utils/is-uuid"], function (_exports, _isUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isArrayOfIds;

  function isArrayOfIds(arr) {
    if (!Ember.isArray(arr)) {
      return false;
    }

    return arr.every(e => (0, _isUuid.default)(e));
  }
});