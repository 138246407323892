define("@fleetbase/frontend/utils/humanize", ["exports", "ember-cli-string-helpers/helpers/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = humanize;

  function humanize(string) {
    return (0, _humanize.humanize)([string]);
  }
});