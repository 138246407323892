define("@fleetbase/frontend/utils/strip-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stripHtml;

  function stripHtml(string) {
    return string.replace(/<\/?[^>]+(>|$)/g, '');
  }
});