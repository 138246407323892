define("@fleetbase/frontend/components/cell/model-link-list", ["exports", "@fleetbase/ui/components/cell/model-link-list"], function (_exports, _modelLinkList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modelLinkList.default;
    }
  });
});