define("@fleetbase/frontend/components/ui/checkbox", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="relative flex items-start" ...attributes>
      <div class="flex items-center h-5">
          <Input
              id={{this.id}}
              checked={{@value}}
              type="checkbox"
              class="w-4 h-4 transition duration-150 ease-in-out form-checkbox dark:bg-gray-800 dark:border-gray-900 {{this.colorClass}} {{@inputClass}}"
              {{on "change" (fn this.toggle)}}
          />
      </div>
      <div class="ml-2 text-sm leading-5">
          <label for={{this.id}} class="font-medium text-gray-700 dark:text-gray-100">
              {{@label}}
              {{yield}}
          </label>
      </div>
  </div>
  */
  {
    "id": "cxLz/Fzt",
    "block": "[[[11,0],[24,0,\"relative flex items-start\"],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center h-5\"],[12],[1,\"\\n        \"],[8,[39,0],[[16,1,[30,0,[\"id\"]]],[16,\"checked\",[30,2]],[16,0,[29,[\"w-4 h-4 transition duration-150 ease-in-out form-checkbox dark:bg-gray-800 dark:border-gray-900 \",[30,0,[\"colorClass\"]],\" \",[30,3]]]],[24,4,\"checkbox\"],[4,[38,1],[\"change\",[28,[37,2],[[30,0,[\"toggle\"]]],null]],null]],null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ml-2 text-sm leading-5\"],[12],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,0,[\"id\"]]],[14,0,\"font-medium text-gray-700 dark:text-gray-100\"],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n            \"],[18,5,null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\",\"@inputClass\",\"@label\",\"&default\"],false,[\"input\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/ui/checkbox.hbs",
    "isStrictMode": false
  });

  let UiCheckboxComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class UiCheckboxComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "checked", _descriptor, this);

      _initializerDefineProperty(this, "colorClass", _descriptor2, this);
    }

    /**
     * Generates a unique ID for this checkbox instance
     *
     * @var {String}
     */
    get id() {
      return Ember.guidFor(this);
    }
    /**
     * Whether this checkbox is checked or not
     *
     * @param {Boolean} checked
     */


    /**
     * Toggles the checkbox and sends up an action
     *
     * @void
     */
    toggle(event) {
      this.checked = event.target.checked; // trigger toggle action if any

      if (this.args.onToggle && typeof this.args.onToggle === 'function') {
        this.args.onToggle(this.checked, event);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "checked", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "colorClass", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'text-blue-500';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = UiCheckboxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiCheckboxComponent);
});