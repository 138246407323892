define("@fleetbase/frontend/helpers/console-url", ["exports", "@fleetbase/frontend/utils/console-url"], function (_exports, _consoleUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function consoleUrl(_ref) {
    let [path = '', queryParams = {}] = _ref;
    return (0, _consoleUrl.default)(path, queryParams);
  });

  _exports.default = _default;
});