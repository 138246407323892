define("@fleetbase/frontend/modifiers/inline-size", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-empty-pattern */
  var _default = (0, _emberModifier.modifier)(function inlineSize(element, _ref) {
    let [] = _ref;
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (options.width) {
      element.style.width = `${options.width}px`;
    }

    if (options.height) {
      element.style.height = `${options.height}px`;
    }
  });

  _exports.default = _default;
});