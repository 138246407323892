define("@fleetbase/frontend/routes/portal/blog/post", ["exports", "@fleetbase/frontend/utils/base-url"], function (_exports, _baseUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PortalBlogPostRoute = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class PortalBlogPostRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "title", _descriptor, this);

      _initializerDefineProperty(this, "description", _descriptor2, this);

      _initializerDefineProperty(this, "headTags", _descriptor3, this);
    }

    model(_ref) {
      let {
        slug
      } = _ref;
      return this.store.queryRecord('post', {
        slug,
        single: true
      });
    }

    afterModel(model) {
      this.title = model.title;
      this.description = model.excerpt;
      this.headTags = [// standard meta tags
      {
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-robots-tag',
        attrs: {
          name: 'robots',
          content: 'index, follow'
        }
      }, // opengraph meta tags - facebook
      {
        type: 'meta',
        tagId: 'meta-og-title-tag',
        attrs: {
          property: 'og:title',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description-tag',
        attrs: {
          property: 'og:description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-type-tag',
        attrs: {
          property: 'og:type',
          content: 'article'
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-url-tag',
        attrs: {
          property: 'og:url',
          content: (0, _baseUrl.default)('products/fleet-ops')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image-tag',
        attrs: {
          property: 'og:image',
          content: (0, _baseUrl.default)('images/fleetbase-console.png')
        }
      }, // twitter meta tags
      {
        type: 'meta',
        tagId: 'meta-twitter-card-tag',
        attrs: {
          name: 'twitter:card',
          content: 'summary'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-site-tag',
        attrs: {
          name: 'twitter:site',
          content: '@fleetbase_io'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-title-tag',
        attrs: {
          name: 'twitter:title',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-description-tag',
        attrs: {
          name: 'twitter:description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-image-src-tag',
        attrs: {
          name: 'twitter:image:src',
          content: (0, _baseUrl.default)('images/fleetbase-console.png')
        }
      }, // google+ meta tags
      {
        type: 'meta',
        tagId: 'meta-gplus-name-tag',
        attrs: {
          itemprop: 'name',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-gplus-description-tag',
        attrs: {
          itemprop: 'description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-gplus-image-tag',
        attrs: {
          itemprop: 'image',
          content: model.image_url
        }
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "headTags", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PortalBlogPostRoute;
});