define("@fleetbase/frontend/tailwind/tailwind.config", [], function () {
  "use strict";

  /* eslint-disable no-undef */
  module.exports = {
    purge: [],
    theme: {
      extend: {
        colors: {
          sky: {
            100: '#e6f0fb',
            200: '#bad5f5',
            300: '#8dbbef',
            400: '#61a0e8',
            500: '#3485e2',
            600: '#1c6cc7',
            700: '#16539a',
            800: '#103b6d',
            900: '#092341'
          },
          gray: {
            100: '#EBF1F5',
            200: '#D9E3EA',
            300: '#C5D2DC',
            400: '#9BA9B4',
            500: '#707D86',
            600: '#55595F',
            700: '#33363A',
            800: '#25282C',
            900: '#151719'
          },
          purple: {
            100: '#F4F4FF',
            200: '#E2E1FF',
            300: '#CBCCFF',
            400: '#ABABFF',
            500: '#8D8DFF',
            600: '#5D5DFF',
            700: '#4B4ACF',
            800: '#38379C',
            900: '#262668'
          }
        },
        spacing: {
          '9/16': '56.25%',
          '3/4': '75%',
          '1/1': '100%'
        },
        fontFamily: {
          inter: ['Inter', 'sans-serif'],
          'architects-daughter': ['"Architects Daughter"', 'sans-serif']
        },
        fontSize: {
          xs: '0.75rem',
          sm: '0.875rem',
          base: '1rem',
          lg: '1.125rem',
          xl: '1.25rem',
          '2xl': '1.5rem',
          '3xl': '2rem',
          '4xl': '2.5rem',
          '5xl': '3.25rem',
          '6xl': '4rem'
        },
        inset: {
          full: '100%'
        },
        letterSpacing: {
          tighter: '-0.02em',
          tight: '-0.01em',
          normal: '0',
          wide: '0.01em',
          wider: '0.02em',
          widest: '0.4em'
        },
        minWidth: {
          10: '2.5rem'
        },
        scale: {
          98: '.98'
        }
      }
    },
    plugins: [// eslint-disable-next-line global-require
    require("@tailwindcss/forms")]
  };
});