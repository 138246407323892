define("@fleetbase/frontend/routes/portal/solutions/hyperlocal-mobile-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalSolutionsHyperlocalMobileAppRoute extends Ember.Route {}

  _exports.default = PortalSolutionsHyperlocalMobileAppRoute;
});