define("@fleetbase/frontend/components/hubspot-form", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id={{this.componentId}} class="hubspot-form-wrapper" ...attributes {{did-insert (fn this.createHubspotForm)}}>
      {{yield}}
  </div>
  */
  {
    "id": "t1BTD0fC",
    "block": "[[[11,0],[16,1,[30,0,[\"componentId\"]]],[24,0,\"hubspot-form-wrapper\"],[17,1],[4,[38,0],[[28,[37,1],[[30,0,[\"createHubspotForm\"]]],null]],null],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"fn\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/hubspot-form.hbs",
    "isStrictMode": false
  });

  let HubspotFormComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class HubspotFormComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "componentId", _descriptor, this);
    }

    createHubspotForm(target) {
      // eslint-disable-next-line no-undef
      hbspt.forms.create({
        portalId: this.args.portalId,
        formId: this.args.formId,
        target: `#${this.componentId}`
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "componentId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.guidFor(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createHubspotForm", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "createHubspotForm"), _class.prototype)), _class));
  _exports.default = HubspotFormComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HubspotFormComponent);
});