define("@fleetbase/frontend/routes/portal/company/career", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalCompanyCareerRoute extends Ember.Route {
    model(_ref) {
      let {
        slug
      } = _ref;
      return this.store.queryRecord('post', {
        slug,
        single: true,
        type: 'career'
      });
    }

  }

  _exports.default = PortalCompanyCareerRoute;
});