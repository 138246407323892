define("@fleetbase/frontend/controllers/portal/blog/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PortalBlogIndexController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('author', 'store'), _dec7 = Ember.computed('category', 'store'), _dec8 = Ember.computed('store', 'tagged'), (_class = class PortalBlogIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "queryParams", _descriptor, this);

      _initializerDefineProperty(this, "page", _descriptor2, this);

      _initializerDefineProperty(this, "category", _descriptor3, this);

      _initializerDefineProperty(this, "author", _descriptor4, this);

      _initializerDefineProperty(this, "tagged", _descriptor5, this);
    }

    get authorRecord() {
      if (this.author) {
        const authors = this.store.peekAll('user');
        return authors.find(author => author.slug === this.author || author.id === this.author);
      }

      return false;
    }

    get categoryRecord() {
      if (this.category) {
        const categories = this.store.peekAll('category');
        return categories.find(category => category.slug === this.category);
      }

      return false;
    }

    get tagRecord() {
      if (this.tagged) {
        const tags = this.store.peekAll('tag');
        return tags.find(tag => tag.slug === this.tagged);
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['page', 'sort', 'limit', 'category', 'author', 'tagged'];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "author", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tagged", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "authorRecord", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "authorRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "categoryRecord", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "categoryRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tagRecord", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "tagRecord"), _class.prototype)), _class));
  _exports.default = PortalBlogIndexController;
});