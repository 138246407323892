define("@fleetbase/frontend/routes/portal/solutions/transport-management-system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalSolutionsTransportManagementSystemRoute extends Ember.Route {}

  _exports.default = PortalSolutionsTransportManagementSystemRoute;
});