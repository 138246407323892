define("@fleetbase/frontend/routes/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalRoute extends Ember.Route {
    async setupController(controller, model) {
      super.setupController(controller, model);
      controller.recentPosts = await this.store.query('post', {
        type: 'blog',
        limit: 3,
        sort: 'id '
      });
    }

  }

  _exports.default = PortalRoute;
});