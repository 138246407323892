define("@fleetbase/frontend/utils/refresh-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = refreshRoute;

  function refreshRoute(controller) {
    if (controller instanceof Ember.Controller) {
      return controller.target.targetState.router.refresh();
    }

    return new Error(`Invalid controller provided.`);
  }
});