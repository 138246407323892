define("@fleetbase/frontend/routes/portal/company/careers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalCompanyCareersRoute extends Ember.Route {
    model(params) {
      return this.store.query('post', {
        type: 'career',
        ...params
      });
    }

  }

  _exports.default = PortalCompanyCareersRoute;
});