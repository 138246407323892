define("@fleetbase/frontend/utils/pagination/items", ["exports", "@fleetbase/frontend/utils/pagination/truncate-pages", "@fleetbase/frontend/utils/get-int"], function (_exports, _truncatePages, _getInt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PaginationItems = (_dec = Ember.computed('currentPage', 'totalPages'), _dec2 = Ember.computed('currentPage', 'totalPages', 'numPagesToShow', 'showFL'), _dec3 = Ember.computed('currentPage', 'numPagesToShow', 'pageItemsAll', 'pageItemsTruncated', 'totalPages', 'truncatePages'), (_class = class PaginationItems extends Ember.Object {
    /**
     * Gets all the page items
     *
     * @var {Array}
     */
    get pageItemsAll() {
      const currentPage = (0, _getInt.default)(this, 'currentPage');
      const totalPages = (0, _getInt.default)(this, 'totalPages');
      let res = Ember.A();

      for (let i = 1; i <= totalPages; i++) {
        res.push({
          page: i,
          current: currentPage === i,
          dots: false
        });
      }

      return res;
    }
    /**
     * Returns the truncated version of the page items
     *
     * @var {Array}
     */


    get pageItemsTruncated() {
      const currentPage = (0, _getInt.default)(this, 'currentPage');
      const totalPages = (0, _getInt.default)(this, 'totalPages');
      const numPagesToShow = (0, _getInt.default)(this, 'numPagesToShow');
      const showFL = this.showFL;

      const truncatedPageItems = _truncatePages.default.create({
        currentPage,
        totalPages,
        numPagesToShow,
        showFL
      });

      const pages = truncatedPageItems.pagesToShow;
      let next = pages[0];
      return pages.map(function (page) {
        var h = {
          page: page,
          current: currentPage === page,
          dots: next !== page
        };
        next = page + 1;
        return h;
      });
    }

    get pageItems() {
      if (this.truncatePages) {
        return this.pageItemsTruncated;
      } else {
        return this.pageItemsAll;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "pageItemsAll", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pageItemsAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pageItemsTruncated", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "pageItemsTruncated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pageItems", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "pageItems"), _class.prototype)), _class));
  _exports.default = PaginationItems;
});