define("@fleetbase/frontend/utils/is-iterable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isIterable;

  function isIterable(obj) {
    if (obj == null) {
      return false;
    }

    return typeof obj[Symbol.iterator] === 'function';
  }
});