define("@fleetbase/frontend/helpers/transition-to", ["exports", "@fleetbase/frontend/utils/transform-query-params"], function (_exports, _transformQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-classic-classes
  var _default = Ember.Helper.extend({
    router: Ember.computed(function () {
      // eslint-disable-next-line ember/no-private-routing-service
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),

    compute(_ref) {
      let [routeName, ...params] = _ref;
      const router = this.router;
      return function () {
        for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        const args = params.concat(invocationArgs);
        const transitionArgs = params.length ? [routeName, ...params] : [routeName];
        router.transitionTo(...(0, _transformQueryParams.default)(transitionArgs));
        return args;
      };
    }

  });

  _exports.default = _default;
});