define("@fleetbase/frontend/snippets/examples/sdk-create-order", ["@fleetbase/sdk"], function (_sdk) {
  "use strict";

  // BEGIN-SNIPPET sdk-create-order
  const fleetbase = new _sdk.default(`{your_api_key}`);
  const pickup = new _sdk.Place({
    name: 'Nike Warehouse',
    location: new _sdk.Point(35.0121718, -89.8945123)
  });
  const dropoff = new _sdk.Place({
    name: 'Distribution Center',
    location: new _sdk.Point(35.025949, -89.9295741)
  });
  const payload = new _sdk.Payload({
    pickup,
    dropoff,
    entities: [new _sdk.Entity({
      name: 'Nike Stock',
      width: 30,
      height: 42,
      length: 43,
      dimensions_unit: 'cm',
      weight: 33,
      weight_unit: 'kg'
    })]
  });
  fleetbase.orders.create({
    payload
  }).then(order => console.log(order)); // END-SNIPPET
});