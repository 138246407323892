define("@fleetbase/frontend/authenticators/api", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import { get } from '@ember/object';
  let ApiAuthenticator = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ApiAuthenticator extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fetch", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);
    }

    /**
     * Restore session from server
     *
     * @param {object} data
     * @return {Promise}
     */
    restore(data) {
      return this.fetch.switchNamespaceTo('int/v1').get('auth/session', {}, {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      }).then(response => {
        if (response.restore === false) {
          return Promise.reject(new Error(response.error));
        }

        return response;
      });
    }
    /**
     * Authenticates a users credentials
     *
     * @param {object} credentials
     * @param {boolean} remember
     * @param {string} path
     */


    authenticate() {
      let credentials = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let remember = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'auth/login';
      return this.fetch.switchNamespaceTo('int/v1').post(path, { ...credentials,
        remember
      }).then(response => {
        if (response.errors) {
          return Promise.reject(new Error(response.errors.firstObject || 'Authentication failed!'));
        }

        return response;
      });
    }
    /**
     * Invalidates the current session
     *
     * @param {object} data
     */
    // eslint-disable-next-line no-unused-vars


    invalidate(data) {// return this.fetch.switchNamespaceTo('int/v1').post('auth/logout');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApiAuthenticator;
});