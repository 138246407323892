define("@fleetbase/frontend/templates/portal/products/storefront-package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TUp5VHbc",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@fleetbase/frontend/templates/portal/products/storefront-package.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});