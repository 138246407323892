define("@fleetbase/frontend/serializers/vendor", ["exports", "@fleetbase/frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VendorSerializer extends _application.default {}

  _exports.default = VendorSerializer;
});