define("@fleetbase/frontend/components/code-snippet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (get-code-snippet @name) as |snippet|}}
      <CodeBlock @code={{snippet.source}} @language={{snippet.language}} />
  {{/let}}
  */
  {
    "id": "dl+bLdbs",
    "block": "[[[44,[[28,[37,1],[[30,1]],null]],[[[1,\"    \"],[8,[39,2],null,[[\"@code\",\"@language\"],[[30,2,[\"source\"]],[30,2,[\"language\"]]]],null],[1,\"\\n\"]],[2]]]],[\"@name\",\"snippet\"],false,[\"let\",\"get-code-snippet\",\"code-block\"]]",
    "moduleName": "@fleetbase/frontend/components/code-snippet.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});