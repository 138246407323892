define("@fleetbase/frontend/components/gravatar", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <img src="////www.gravatar.com/avatar/{{this.generatedHash}}?s={{this.size}}&d={{this.defaultImage}}" alt={{this.alt}} ...attributes />
  */
  {
    "id": "iacwWHzc",
    "block": "[[[11,\"img\"],[16,\"src\",[29,[\"////www.gravatar.com/avatar/\",[30,0,[\"generatedHash\"]],\"?s=\",[30,0,[\"size\"]],\"&d=\",[30,0,[\"defaultImage\"]]]]],[16,\"alt\",[30,0,[\"alt\"]]],[17,1],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "@fleetbase/frontend/components/gravatar.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-computed-properties-in-native-classes */


  // import md5 from '../utils/md5';
  let GravatarComponent = (_dec = Ember.computed('args.size'), _dec2 = Ember.computed('args.{hash,email}'), _dec3 = Ember.computed('args.{alt,name,email}', 'guid'), (_class = class GravatarComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "guid", Ember.guidFor(this));

      _defineProperty(this, "defaultImage", 'https://s3.ap-southeast-1.amazonaws.com/flb-assets/static/no-avatar.png');
    }

    get size() {
      return this.args.size ?? 250;
    }

    get generatedHash() {
      const {
        hash,
        email
      } = this.args;
      return hash ?? email;
    }

    get alt() {
      const {
        alt,
        name,
        email
      } = this.args;
      return alt ?? name ?? email ?? this.guid;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "size", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "size"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generatedHash", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "generatedHash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alt", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "alt"), _class.prototype)), _class));
  _exports.default = GravatarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GravatarComponent);
});