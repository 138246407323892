define("@fleetbase/frontend/utils/is-model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isModel;

  function isModel(obj) {
    return obj instanceof _model.default;
  }
});