define("@fleetbase/frontend/utils/is-array-of-models", ["exports", "@fleetbase/frontend/utils/is-model"], function (_exports, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isArrayOfModels;

  function isArrayOfModels(arr) {
    if (!Ember.isArray(arr)) {
      return false;
    }

    return arr.every(e => (0, _isModel.default)(e));
  }
});