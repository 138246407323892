define("@fleetbase/frontend/templates/portal/company/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhcgZniz",
    "block": "[[[10,0],[14,0,\"relative max-w-6xl mx-auto h-0 pointer-events-none\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"relative\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"max-w-6xl mx-auto px-4 sm:px-6 relative\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"pt-32 pb-12 md:pt-40 md:pb-20\"],[12],[1,\"\\n\\n            \"],[10,0],[14,0,\"max-w-3xl mx-auto text-center pb-12 md:pb-16\"],[12],[1,\"\\n                \"],[10,\"h1\"],[14,0,\"h1 mb-4\"],[14,\"data-aos\",\"fade-up\"],[12],[1,\"How can we help you?\"],[13],[1,\"\\n                \"],[10,2],[14,0,\"text-xl text-gray-400\"],[14,\"data-aos\",\"fade-up\"],[14,\"data-aos-delay\",\"200\"],[12],[1,\"We have custom\\n                    plans to power your business. Tell us your needs, and we’ll contact you shortly.\"],[13],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,\"script\"],[14,\"src\",\"https://fleetbase.myfreshworks.com/crm/sales/web_forms/8cf53268c13787335b09bb069cdf33689caa192bea7358da015f23f50c663c11/form.js\"],[14,\"crossorigin\",\"anonymous\"],[14,1,\"fs_8cf53268c13787335b09bb069cdf33689caa192bea7358da015f23f50c663c11\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"open/page-illustration\"]]",
    "moduleName": "@fleetbase/frontend/templates/portal/company/contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});