define("@fleetbase/frontend/routes/portal/landing-page/identity-and-access-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortalLandingPageIdentityAndAccessManagementRoute extends Ember.Route {}

  _exports.default = PortalLandingPageIdentityAndAccessManagementRoute;
});