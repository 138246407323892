define("@fleetbase/frontend/utils/get-model-name", ["exports", "@fleetbase/frontend/utils/is-model"], function (_exports, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getModelName;

  function getModelName(model) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'resource';

    if ((0, _isModel.default)(model)) {
      return Ember.get(model, '_internalModel.modelName') || defaultValue;
    }

    return defaultValue;
  }
});