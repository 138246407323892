define("@fleetbase/frontend/components/scroll-link", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a href="" class="{{if @expandable 'expandable'}}" ...attributes {{did-insert (fn this.setupScroll)}} {{on "click" (fn this.onClick)}}>{{yield}}</a>
  */
  {
    "id": "pzxsjqdC",
    "block": "[[[11,3],[24,6,\"\"],[16,0,[29,[[52,[30,1],\"expandable\",null]]]],[17,2],[4,[38,1],[[28,[37,2],[[30,0,[\"setupScroll\"]]],null]],null],[4,[38,3],[\"click\",[28,[37,2],[[30,0,[\"onClick\"]]],null]],null],[12],[18,3,null],[13]],[\"@expandable\",\"&attrs\",\"&default\"],false,[\"if\",\"did-insert\",\"fn\",\"on\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/scroll-link.hbs",
    "isStrictMode": false
  });

  let ScrollLinkComponent = (_dec = Ember.computed('args.target'), _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ScrollLinkComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isActive", _descriptor, this);
    }

    /**
     * The dom targer to scroll on
     *
     * @var string
     */
    get target() {
      const {
        target
      } = this.args;

      if (target) {
        return target;
      }

      return 'html, body';
    }
    /**
     * Determines link active state
     *
     * @var boolean
     */


    /**
     * On insert we need to watch for location.hash change to handle the active state
     *
     * @void
     */
    setupScroll(element) {
      var _this$args;

      const {
        hash
      } = window.location;
      window.addEventListener('hashchange', () => {
        this.isActive = hash === element.hash;
      });

      if (hash === element.hash) {
        this.isActive = true;
        this.scrollTo(element);
        this.simulateClick(element);
      } // add scroll-link class


      element.classList.add('scroll-link');

      if (((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.expandable) === true) {
        element.classList.add('expandable');
      }

      this.observeContentScroll(element);
    }

    observeContentScroll(element) {
      const scrollTarget = document.querySelector(this.target);
      const contentTarget = document.querySelector(element.hash);

      if (!scrollTarget || !contentTarget) {
        return;
      }

      scrollTarget.addEventListener('scroll', () => {
        const start = contentTarget.offsetTop;
        const end = contentTarget.offsetTop + contentTarget.offsetHeight - 100;
        const scrollY = scrollTarget.scrollTop;

        if (scrollY >= start && scrollY <= end) {
          this.simulateClick(element);
        }
      });
    }
    /**
     * On click we want to scroll to that section and set hash in url
     *
     * @void
     */


    onClick(event) {
      var _this$args2;

      event.preventDefault();
      const {
        target
      } = event;
      const {
        hash
      } = window.location;

      if (((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.expandable) === true) {
        this.expand(target);
      }

      if (hash === (target === null || target === void 0 ? void 0 : target.hash)) {
        return;
      }

      this.scrollTo(target);
      this.simulateClick(target);
    }

    setHash(hash) {
      if (history.pushState) {
        history.pushState(null, null, hash);
      } else {
        location.hash = hash;
      }
    }

    scrollTo(target) {
      const expectingElement = document.querySelector(target.hash);

      if (expectingElement) {
        document.querySelector(this.target).scrollTop = expectingElement === null || expectingElement === void 0 ? void 0 : expectingElement.offsetTop;
      }
    }

    expand(target) {
      document.querySelectorAll(`.${[...target.classList].join('.')}`).forEach(link => link.classList.remove('expanded'));
      target.classList.add('expanded');
    }

    simulateClick(target) {
      var _target$closest;

      // set hash
      this.setHash(target.hash); // make active

      document.querySelectorAll(`a.sidebar-nav-item`).forEach(link => link.classList.remove('active')); // set scroll link to active

      target.classList.add('active'); // if has a parent

      if ((_target$closest = target.closest('a + ul')) !== null && _target$closest !== void 0 && _target$closest.previousElementSibling) {
        this.expand(target.closest('a + ul').previousElementSibling);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "target", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "target"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupScroll", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "observeContentScroll", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "observeContentScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = ScrollLinkComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ScrollLinkComponent);
});