define("@fleetbase/frontend/snippets/examples/sdk-dispatch-order", ["@fleetbase/sdk"], function (_sdk) {
  "use strict";

  // BEGIN-SNIPPET sdk-dispatch-order
  const fleetbase = new _sdk.default('{your_api_key}');
  fleetbase.orders.findRecord('{order_id}').then(order => {
    order.dispatch().then(order => {
      console.log(`Order dispatched at ${order.dispatched_at}`);
    });
  }); // END-SNIPPET
});