define("@fleetbase/frontend/utils/array-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arrayRange;

  function arrayRange(size) {
    let startAt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return [...Array(size + 1).keys()].map(i => i + startAt);
  }
});