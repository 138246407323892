define("@fleetbase/frontend/components/portal/hero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="fleetbase-hero relative overflow-hidden" ...attributes>
      <div class="relative lg:pt-6 pb-16 sm:pb-24">
          <main class="lg:mt-24">
              <div class="mx-auto max-w-7xl">
                  {{yield}}
              </div>
          </main>
      </div>
  </div>
  */
  {
    "id": "KJIustZO",
    "block": "[[[11,0],[24,0,\"fleetbase-hero relative overflow-hidden\"],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"relative lg:pt-6 pb-16 sm:pb-24\"],[12],[1,\"\\n        \"],[10,\"main\"],[14,0,\"lg:mt-24\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"mx-auto max-w-7xl\"],[12],[1,\"\\n                \"],[18,2,null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/portal/hero.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});