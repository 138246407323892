define("@fleetbase/frontend/controllers/portal/products/navigator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PortalProductsNavigatorController = (_dec = Ember._action, (_class = class PortalProductsNavigatorController extends Ember.Controller {
    downloadFor() {
      let platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'android';
      let url = 'https://play.google.com/store/apps/details?id=com.fleetbase.navigator';

      if (platform === 'ios') {
        url = 'https://apps.apple.com/us/app/fleetbase/id1554208255';
      }

      return window.open(url, `_${platform}`);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "downloadFor", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "downloadFor"), _class.prototype)), _class));
  _exports.default = PortalProductsNavigatorController;
});