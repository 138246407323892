define("@fleetbase/frontend/services/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FiltersService extends Ember.Service {}

  _exports.default = FiltersService;
});