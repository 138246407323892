define('@fleetbase/frontend/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('@fleetbase/frontend');
} else {

          var exports = {
            'default': {"modulePrefix":"@fleetbase/frontend","environment":"development","rootURL":"/","locationType":"auto","pageTitle":{"replace":true},"EmberENV":{"FEATURES":{},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true},"googleFonts":["Inter:wght@100;200;300;400;500;600"],"fontawesome":{"defaultPrefix":"fad"},"ember-simple-auth":{"routeAfterAuthentication":"console"},"ember-local-storage":{"namespace":"@fleetbase/frontend","keyDelimiter":"/","includeEmberDataSupport":true},"ember-light-table":{"enableSync":true},"ember-cli-notifications":{"includeFontAwesome":true,"autoClear":true,"clearDuration":2400},"fastboot":{"hostWhitelist":["fleetbase.io","www.fleetbase.io",{},{}]},"metricsAdapters":[{"name":"GoogleAnalytics","environments":["production"],"config":{"id":"UA-132746899-1","debug":true,"trace":true,"sendHitTask":false,"require":["ecommerce"]}}],"APP":{"name":"@fleetbase/frontend","version":"0.0.0+62ca9ca8"},"API":{"host":"https://v2api.fleetbase.engineering","namespace":"frontend/v1"},"ember-collapsible-panel":{},"exportApplicationGlobal":true,"emberInitials":{"image":{"defaultImageUrl":""},"gravatar":{"defaultImageUrl":""}}}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
