define("@fleetbase/frontend/routes/portal/blog/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PortalBlogIndexRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        query: {
          refreshModel: true
        },
        author: {
          refreshModel: true
        },
        tagged: {
          refreshModel: true
        },
        category: {
          refreshModel: true
        }
      });
    }

    model(params) {
      return this.store.query('post', {
        type: 'blog',
        ...params
      });
    }

  }

  _exports.default = PortalBlogIndexRoute;
});