define("@fleetbase/frontend/components/portal/grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="grid grid-cols-{{or @columns 1}} gap-{{or @gap 0}}" ...attributes>
      {{yield}}
  </div>
  */
  {
    "id": "eZfWPf8y",
    "block": "[[[11,0],[16,0,[29,[\"grid grid-cols-\",[28,[37,0],[[30,1],1],null],\" gap-\",[28,[37,0],[[30,2],0],null]]]],[17,3],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n\"],[13]],[\"@columns\",\"@gap\",\"&attrs\",\"&default\"],false,[\"or\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/portal/grid.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});