define("@fleetbase/frontend/utils/isset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isset;

  function isset(target) {
    let key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (key === null) {
      return !Ember.isBlank(target);
    }

    return !Ember.isBlank(Ember.get(target, key));
  }
});