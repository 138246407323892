define("@fleetbase/frontend/services/modals-manager", ["exports", "ember-bootstrap-modals-manager/services/modals-manager"], function (_exports, _modalsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ModalsManagerService extends _modalsManager.default {
    /**
     * Same as onClickConfirm but allows a handler to run then resolve by user
     *
     * @param {EbmmModalOptions} v
     */
    onClickConfirmWithDone(v) {
      const done = this.done.bind(this, v);

      if (this.options.confirm && typeof this.options.confirm === 'function') {
        const response = this.options.confirm(this, done);

        if (response && typeof response.then === 'function') {
          return response.finally(() => {
            done();
          });
        }

        return;
      }

      return done(v);
    }
    /**
     * Same as onClickDecline but allows a handler to run then resolve by user
     *
     * @param {EbmmModalOptions} v
     */


    onClickDeclineWithDone(v) {
      const done = () => {
        var _this$modalDefer;

        Ember.set(this, 'modalIsOpened', false);
        (_this$modalDefer = this.modalDefer) === null || _this$modalDefer === void 0 ? void 0 : _this$modalDefer.resolve(v);
        this.clearOptions();
      };

      if (this.options.decline && typeof this.options.decline === 'function') {
        const response = this.options.decline(this, done);

        if (response && typeof response.then === 'function') {
          return response.finally(() => {
            return done();
          });
        }

        return;
      }

      return done(v);
    }
    /**
     * Closes the modal and cleans up
     *
     * @void
     */


    done() {
      let v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new Promise(resolve => {
        var _this$modalDefer2;

        Ember.set(this, 'modalIsOpened', false);
        (_this$modalDefer2 = this.modalDefer) === null || _this$modalDefer2 === void 0 ? void 0 : _this$modalDefer2.resolve(v);
        this.clearOptions();
        resolve(true);
      });
    }
    /**
     * Shows `confirm`-modal
     *
     * @method confirm
     * @param {object} options
     * @return {RSVP.Promise}
     */


    confirm(options) {
      return this.show(`modals/confirm`, options);
    }
    /**
     * Retrieves an option
     *
     * @param {String} key
     * @return {Mixed}
     */


    getOption(key) {
      return this.options[key] || null;
    }
    /**
     * Updates an option in the service
     *
     * @param {String} key
     * @param {Mixed} value
     * @void
     */


    setOption(key, value) {
      this.options = { ...this.options,
        [key]: value
      };
    }
    /**
     * Allows multiple options to be updated
     *
     * @param {Object} options
     * @void
     */


    setOptions() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.options = { ...this.options,
        ...options
      };
    }
    /**
     * Alias to start loading indicator on modal
     *
     * @void
     */


    startLoading() {
      this.setOption('isLoading', true);
    }
    /**
     * Alias to stop loading indicator on modal
     *
     * @void
     */


    stopLoading() {
      this.setOption('isLoading', false);
    }

  }

  _exports.default = ModalsManagerService;
});