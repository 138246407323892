define("@fleetbase/frontend/helpers/n-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function nA(_ref) {
    let [value, placeholder = 'No value'] = _ref;
    return value || placeholder;
  });

  _exports.default = _default;
});