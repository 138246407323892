define("@fleetbase/frontend/routes/portal/products/blockchain", ["exports", "@fleetbase/frontend/utils/base-url"], function (_exports, _baseUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PortalServicesBlockchainRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _defineProperty(this, "title", 'Fleetbase - Blockchain for validation');

      _defineProperty(this, "description", 'Fleetbase is joining the Blockchain revolution to enable a ledger of end to end verified activity, as well as enable smart contracts & invoices between companies throughout the supply chain.');

      _defineProperty(this, "headTags", [// standard meta tags
      {
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-robots-tag',
        attrs: {
          name: 'robots',
          content: 'index, follow'
        }
      }, // opengraph meta tags - facebook
      {
        type: 'meta',
        tagId: 'meta-og-title-tag',
        attrs: {
          property: 'og:title',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description-tag',
        attrs: {
          property: 'og:description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-type-tag',
        attrs: {
          property: 'og:type',
          content: 'product'
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-url-tag',
        attrs: {
          property: 'og:url',
          content: (0, _baseUrl.default)('products/blockchain')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image-tag',
        attrs: {
          property: 'og:image',
          content: (0, _baseUrl.default)('images/fleetbase-console.png')
        }
      }, // twitter meta tags
      {
        type: 'meta',
        tagId: 'meta-twitter-card-tag',
        attrs: {
          name: 'twitter:card',
          content: 'summary_large_image'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-site-tag',
        attrs: {
          name: 'twitter:site',
          content: '@fleetbase_io'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-title-tag',
        attrs: {
          name: 'twitter:title',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-description-tag',
        attrs: {
          name: 'twitter:description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-image-src-tag',
        attrs: {
          name: 'twitter:image:src',
          content: (0, _baseUrl.default)('images/fleetbase-console.png')
        }
      }, // google+ meta tags
      {
        type: 'meta',
        tagId: 'meta-gplus-name-tag',
        attrs: {
          itemprop: 'name',
          content: this.title
        }
      }, {
        type: 'meta',
        tagId: 'meta-gplus-description-tag',
        attrs: {
          itemprop: 'description',
          content: this.description
        }
      }, {
        type: 'meta',
        tagId: 'meta-gplus-image-tag',
        attrs: {
          itemprop: 'image',
          content: (0, _baseUrl.default)('images/fleetbase-console.png')
        }
      }]);
    }

  }

  _exports.default = PortalServicesBlockchainRoute;
});