define("@fleetbase/frontend/transforms/array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ArrayTransform extends _transform.default {
    deserialize(serialized) {
      return Array.from(serialized);
    }

    serialize(deserialized) {
      return Array.from(deserialized);
    }

  }

  _exports.default = ArrayTransform;
});