define("@fleetbase/frontend/components/link-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="inline-flex items-center text-white bg-black rounded-full p-1.5 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200" ...attributes>
      <LinkTo @route={{@firstRoute}} class="px-4 py-1.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide {{if (eq @activeRoute "first") 'bg-sky-500' 'bg-black'}} rounded-full">{{@firstTitle}}</LinkTo>
      <LinkTo @route={{@secondRoute}} class="px-4 py-1.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide {{if (eq @activeRoute "second") 'bg-sky-500' 'bg-black'}} rounded-full">{{@secondTitle}}</LinkTo>
      <svg class="ml-2 w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
  </div>
  */
  {
    "id": "+nxmAZeZ",
    "block": "[[[11,0],[24,0,\"inline-flex items-center text-white bg-black rounded-full p-1.5 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200\"],[17,1],[12],[1,\"\\n    \"],[8,[39,0],[[16,0,[29,[\"px-4 py-1.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide \",[52,[28,[37,2],[[30,2],\"first\"],null],\"bg-sky-500\",\"bg-black\"],\" rounded-full\"]]]],[[\"@route\"],[[30,3]]],[[\"default\"],[[[[1,[30,4]]],[]]]]],[1,\"\\n    \"],[8,[39,0],[[16,0,[29,[\"px-4 py-1.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide \",[52,[28,[37,2],[[30,2],\"second\"],null],\"bg-sky-500\",\"bg-black\"],\" rounded-full\"]]]],[[\"@route\"],[[30,5]]],[[\"default\"],[[[[1,[30,6]]],[]]]]],[1,\"\\n    \"],[10,\"svg\"],[14,0,\"ml-2 w-5 h-5 text-gray-500\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 20 20\"],[14,\"fill\",\"currentColor\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n        \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@activeRoute\",\"@firstRoute\",\"@firstTitle\",\"@secondRoute\",\"@secondTitle\"],false,[\"link-to\",\"if\",\"eq\"]]",
    "moduleName": "@fleetbase/frontend/components/link-toggle.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});