define("@fleetbase/frontend/services/fetch", ["exports", "ember-concurrency", "ember-local-storage", "@fleetbase/frontend/config/environment", "downloadjs", "fetch", "moment"], function (_exports, _emberConcurrency, _emberLocalStorage, _environment, _downloadjs, _fetch, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FetchService = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = (0, _emberLocalStorage.storageFor)('local-cache'), _dec8 = (0, _emberConcurrency.task)(function* (file) {
    let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let callback = arguments.length > 2 ? arguments[2] : undefined;
    this.refreshHeaders();
    const {
      queue
    } = file;

    try {
      const upload = yield file.upload(`${Ember.get(_environment.default, 'API.host')}/${Ember.get(_environment.default, 'API.namespace')}/files/upload`, {
        data: params,
        mode: 'cors',
        credentials: this.credentials,
        headers: {
          Authorization: `Bearer ${this.session.data.authenticated.token}`
        }
      });
      const model = this.store.push(this.store.normalize('file', Ember.get(upload, 'body.file')));

      if (typeof callback === 'function') {
        callback(model);
      }

      return model;
    } catch (error) {
      queue.remove(file);
      this.notifications.error(error.body.message || `Upload failed.`);
    }
  }).maxConcurrency(3).enqueue(), (_class = class FetchService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_namespace", _descriptor, this);

      _initializerDefineProperty(this, "_headers", _descriptor2, this);

      _defineProperty(this, "credentials", 'include');

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "currentUser", _descriptor5, this);

      _initializerDefineProperty(this, "notifications", _descriptor6, this);

      _initializerDefineProperty(this, "localCache", _descriptor7, this);

      _initializerDefineProperty(this, "uploadFile", _descriptor8, this);
    }

    /**
     * The default namespace for the fetch service
     *
     * @var {String}
     */
    get host() {
      return Ember.get(_environment.default, 'API.host');
    }
    /**
     * The default namespace for the fetch service
     *
     * @var {String}
     */


    get namespace() {
      if (this._namespace) {
        return this._namespace;
      }

      return Ember.get(_environment.default, 'API.namespace');
    }
    /**
     * Setter for namespace
     *
     * @var {Streing}
     */


    set namespace(namespace) {
      this._namespace = namespace;
    }
    /**
     * Mutable namespace property.
     *
     * @var {String}
     */


    /**
     * The headers to send with request.
     *
     * @var {Object}
     */
    get headers() {
      if (this._headers) {
        return this._headers;
      }

      return this.getHeaders();
    }
    /**
     * Setter fucntion to overwrite headers.
     */


    set headers(headers) {
      this._headers = headers;
    }
    /**
     * Set namespace
     *
     * @var {String}
     */


    switchNamespaceTo(namespace) {
      this.namespace = namespace;
      return this;
    }
    /**
     * Gets headers that should be sent with request.
     *
     * @return {Object}
     */


    getHeaders() {
      const headers = {}; // check if user is authenticated

      const isAuthenticated = this.session.isAuthenticated;
      headers['Content-Type'] = 'application/json';

      if (isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }

      return headers;
    }
    /**
     * Gets fresh headers and sets them.
     *
     * @return {Object}
     */


    refreshHeaders() {
      const headers = this.getHeaders();
      this.headers = headers;
      return headers;
    }
    /**
     * Credentials
     *
     * @var {String}
     */


    /**
     * Normalizes a model response from fetch to a ember data model
     *
     * @param  {Object} payload   A response from a network request
     * @param  {String} modelType The type of model to be normalized too
     *
     * @return {Model}            An ember model
     */
    normalizeModel(payload) {
      let modelType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (modelType === null) {
        const modelTypeKeys = Object.keys(payload);
        modelType = modelTypeKeys.length ? modelTypeKeys.firstObject : false;
      }

      if (typeof modelType !== 'string') return false;
      const type = Ember.String.dasherize(modelType);
      const normalized = this.store.push(this.store.normalize(type, payload[modelType]));
      return normalized;
    }
    /**
     * Parses the JSON returned by a network request
     *
     * @param  {Object} response A response from a network request
     * @return {Object}          The parsed JSON, status from the response
     *
     * @return {Promise}
     */


    parseJSON(response) {
      return new Promise((resolve, reject) => response.json().then(json => resolve({
        statusText: response.statusText,
        status: response.status,
        ok: response.ok,
        json
      })).catch(() => {
        reject(new Error('Oops! Something went wrong when handling your request.'));
      }));
    }
    /**
     * The base request method
     *
     * @param {String} path
     * @param {String} method
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    request(path) {
      let method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
      let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      this.refreshHeaders();
      return new Promise((resolve, reject) => {
        return (0, _fetch.default)(options.externalRequest === true ? path : `${this.host}/${this.namespace}/${path}`, {
          method,
          mode: options.mode || 'cors',
          credentials: options.credentials || this.credentials,
          headers: { ...(this.headers || {}),
            ...(options.headers || {})
          },
          ...data
        }).then(this.parseJSON).then(response => {
          // console.log('[fetch:response]', response);
          if (response.ok) {
            if (options.normalizeToEmberData) {
              return resolve(this.normalizeModel(response.json));
            }

            return resolve(response.json);
          }

          return reject(new Error(response.json.errors ? response.json.errors.firstObject : response.statusText));
        }).catch(error => {
          reject(error);
        });
      });
    }
    /**
     * Makes a GET request with fetch
     *
     * @param {String} path
     * @param {Object} query
     * @param {Object} options
     *
     * @return {Promise}
     */


    get(path) {
      let query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const urlParams = !Ember.isBlank(query) ? new URLSearchParams(query).toString() : '';
      return this.request(`${path}${urlParams ? '?' + urlParams : ''}`, 'GET', {}, options);
    }
    /**
     * Makes a GET request with fetch, but if the fetch is stored in local cache,
     * retrieve from storage to prevent unnecessary netwrok request
     *
     * @param {String} path
     * @param {Object} query
     * @param {Object} options
     *
     * @return {Promise}
     */


    cachedGet(path) {
      let query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const pathKey = Ember.String.dasherize(path);
      const pathKeyVersion = (0, _moment.default)().format('DDMMYYYY'); // check to see if in storage already

      if (this.localCache.get(pathKey)) {
        return new Promise(resolve => {
          // get cached data
          const data = this.localCache.get(pathKey); // get the path key version value

          const version = this.localCache.get(`${pathKey}-version`); // if the version is older than 3 days clear it

          if (!version || (0, _moment.default)().diff((0, _moment.default)(version, 'DDMMYYYY'), 'days') > 3 || options.clearData === true) {
            this.localCache.set(pathKey, undefined);
            this.localCache.set(`${pathKey}-version`, undefined);
          } // return cached response


          return resolve(data);
        });
      } // if no cached data request from server


      return this.get(path, query, options).then(response => {
        // cache the response
        this.localCache.set(pathKey, response);
        this.localCache.set(`${pathKey}-version`, pathKeyVersion); // return response

        return response;
      });
    }
    /**
     * Makes a POST request with fetch
     *
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    post(path) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.request(path, 'POST', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Makes a PUT request with fetch
     *
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    put(path) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.request(path, 'PUT', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Makes a DELETE request with fetch
     *
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    delete(path) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.request(path, 'DELETE', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Makes a PATCH request with fetch
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    patch(path) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.request(path, 'PATCH', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Makes a upload request with fetch
     *
     * @param {String} path
     * @param {Array} files
     * @param {Object} options
     *
     * @return {Promise}
     */


    upload(path) {
      let files = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const body = new FormData();
      files.forEach(file => {
        body.append('file', file);
      });
      return this.request(path, 'POST', {
        body
      }, options);
    }
    /**
     * Concurrency task to handle a file upload
     *
     * @void
     */


    /**
     * Downloads blob of the request path to user
     *
     * @param {String} path
     * @param {Object} query
     * @param {Object} options
     *
     * @return {Promise}
     */
    download(path) {
      let query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return new Promise((resolve, reject) => {
        return (0, _fetch.default)(`${this.host}/${this.namespace}/${path}?${!Ember.isBlank(query) ? new URLSearchParams(query).toString() : ''}`, {
          method: 'GET',
          credentials: this.credentials,
          headers: { ...(this.headers || {}),
            ...(options.headers || {})
          }
        }).then(response => response.blob()).then(blob => {
          return resolve((0, _downloadjs.default)(blob, options.fileName, options.mimeType));
        }).catch(error => {
          reject(error);
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_namespace", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_headers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "localCache", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FetchService;
});