define("@fleetbase/frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o6ZcQo+N",
    "block": "[[[8,[39,0],null,[[\"@headTags\"],[[30,0,[\"model\",\"headTags\"]]]],null]],[],false,[\"head-tags\"]]",
    "moduleName": "@fleetbase/frontend/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});