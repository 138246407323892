define("@fleetbase/frontend/routes/docs/guides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DocsGuidesRoute extends Ember.Route {}

  _exports.default = DocsGuidesRoute;
});