define("@fleetbase/frontend/components/typewriter-text", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="typewriter-text" {{did-insert (fn this.setupComponent)}} ...attributes>{{yield}}</span>
  */
  {
    "id": "lKHysIog",
    "block": "[[[11,1],[24,0,\"typewriter-text\"],[17,1],[4,[38,0],[[28,[37,1],[[30,0,[\"setupComponent\"]]],null]],null],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"fn\",\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/typewriter-text.hbs",
    "isStrictMode": false
  });

  let TypewriterTextComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class TypewriterTextComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "nodeRef", _descriptor, this);

      _initializerDefineProperty(this, "interval", _descriptor2, this);

      _initializerDefineProperty(this, "typeInterval", _descriptor3, this);

      _initializerDefineProperty(this, "loop", _descriptor4, this);
    }

    async setupComponent(element) {
      const {
        loop,
        interval,
        typeInterval
      } = this.args;
      this.nodeRef = element;
      this.loop = Ember.isEmpty(loop) ? this.loop : loop;
      this.interval = Ember.isEmpty(interval) ? this.interval : parseInt(interval);
      this.typeInterval = Ember.isEmpty(typeInterval) ? this.typeInterval : parseInt(typeInterval);
      this.startTypeWriting();
    }

    async startTypeWriting() {
      let {
        phrases
      } = this.args;

      if (!phrases) {
        phrases = this.getPhrasesFromText();
      }

      for (let i = 0; i < phrases.length; i++) {
        const phrase = phrases.objectAt(i);
        this.clearText();

        for (let j = 0; j < phrase.length; j++) {
          const char = phrase[j];
          await this.addCharacter(char);
        }

        await this.timeout(this.interval);

        if (this.loop === true) {
          phrases.pushObject(phrase);
        }
      }
    }

    getPhrasesFromText() {
      const text = this.getText();

      if (typeof text === 'string') {
        return text.split(',');
      }

      return [];
    }

    getText() {
      var _this$nodeRef;

      return (_this$nodeRef = this.nodeRef) === null || _this$nodeRef === void 0 ? void 0 : _this$nodeRef.textContent;
    }

    clearText() {
      this.nodeRef.textContent = ' ';
    }

    setText(text) {
      this.nodeRef.textContent = text;
    }

    addCharacter(char) {
      return new Promise(resolve => {
        setTimeout(() => {
          this.nodeRef.textContent += char;
          resolve(true);
        }, this.typeInterval);
      });
    }

    timeout(ms) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, ms);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "nodeRef", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "interval", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1200;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "typeInterval", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 100;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loop", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupComponent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setupComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startTypeWriting", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "startTypeWriting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPhrasesFromText", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "getPhrasesFromText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getText", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "getText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearText", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "clearText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setText", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addCharacter", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "addCharacter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "timeout", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "timeout"), _class.prototype)), _class));
  _exports.default = TypewriterTextComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TypewriterTextComponent);
});