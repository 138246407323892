define("@fleetbase/frontend/components/portal/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="relative overflow-hidden {{@bgClass}}" ...attributes>
      {{!-- pt-6 pb-16 sm:pb-24 --}}
      <div class={{@outerWrapperClass}}> 
          {{!-- mt-16 sm:mt-24 --}}
          <div class="{{@innerWrapperClass}}">
              <div class="mx-auto max-w-7xl {{@yieldWrapperClass}}">
                  {{yield}}
              </div>
          </div>
      </div>
  </section>
  */
  {
    "id": "f9meNGqz",
    "block": "[[[11,\"section\"],[16,0,[29,[\"relative overflow-hidden \",[30,1]]]],[17,2],[12],[1,\"\\n\"],[1,\"    \"],[10,0],[15,0,[30,3]],[12],[1,\" \\n\"],[1,\"        \"],[10,0],[15,0,[29,[[30,4]]]],[12],[1,\"\\n            \"],[10,0],[15,0,[29,[\"mx-auto max-w-7xl \",[30,5]]]],[12],[1,\"\\n                \"],[18,6,null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@bgClass\",\"&attrs\",\"@outerWrapperClass\",\"@innerWrapperClass\",\"@yieldWrapperClass\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@fleetbase/frontend/components/portal/section.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});