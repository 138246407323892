define("@fleetbase/frontend/routes/docs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DocsRoute extends Ember.Route {
    beforeModel() {
      return this.transitionTo('docs.api');
    }

  }

  _exports.default = DocsRoute;
});