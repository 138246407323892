define("@fleetbase/frontend/utils/is-comma-delimited-ids", ["exports", "@fleetbase/frontend/utils/is-uuid"], function (_exports, _isUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isCommaDelimitedIds;

  function isCommaDelimitedIds(v) {
    if (typeof v !== 'string' || !v.includes(',')) {
      return false;
    }

    const arr = v.split(',');
    const is = arr.every(e => (0, _isUuid.default)(e));
    return is ? arr : false;
  }
});