define("@fleetbase/frontend/components/ui/switch", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
      role="checkbox"
      tabindex="0"
      aria-checked="false"
      class="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 cursor-pointer group focus:outline-none"
      ...attributes
      {{on "click" (fn this.toggle @isToggled)}}
  >
      <span aria-hidden="true" class="{{if @isToggled this.activeColorClass "bg-gray-200"}} absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"></span>
      <span
          aria-hidden="true"
          class="{{
              if @isToggled "translate-x-5" "translate-x-0"
          }} absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform group-focus:shadow-outline group-focus:border-blue-300 transition-transform ease-in-out duration-200"
      ></span>
  </span>
  */
  {
    "id": "46qP3HRG",
    "block": "[[[11,1],[24,\"role\",\"checkbox\"],[24,\"tabindex\",\"0\"],[24,\"aria-checked\",\"false\"],[24,0,\"relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 cursor-pointer group focus:outline-none\"],[17,1],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"toggle\"]],[30,2]],null]],null],[12],[1,\"\\n    \"],[10,1],[14,\"aria-hidden\",\"true\"],[15,0,[29,[[52,[30,2],[30,0,[\"activeColorClass\"]],\"bg-gray-200\"],\" absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200\"]]],[12],[13],[1,\"\\n    \"],[10,1],[14,\"aria-hidden\",\"true\"],[15,0,[29,[[52,[30,2],\"translate-x-5\",\"translate-x-0\"],\" absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform group-focus:shadow-outline group-focus:border-blue-300 transition-transform ease-in-out duration-200\"]]],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@isToggled\"],false,[\"on\",\"fn\",\"if\"]]",
    "moduleName": "@fleetbase/frontend/components/ui/switch.hbs",
    "isStrictMode": false
  });

  let UiSwitchComponent = (_dec = Ember.computed('activeColor'), _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class UiSwitchComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "activeColor", 'green');

      _initializerDefineProperty(this, "isToggled", _descriptor, this);
    }

    /**
     * The active color class
     *
     * @var {String}
     */
    get activeColorClass() {
      return `bg-${this.activeColor}-400`;
    }
    /**
     * If the toggle is on or off
     *
     * @var {Boolean}
     */


    /**
     * Event for on/of toggle
     *
     * @void
     */
    toggle(isToggled) {
      this.isToggled = isToggled === false ? true : false;

      if (typeof this.args.onToggle === 'function') {
        this.args.onToggle(this.isToggled);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "activeColorClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "activeColorClass"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isToggled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = UiSwitchComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiSwitchComponent);
});