define("@fleetbase/frontend/templates/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jgw0v2jN",
    "block": "[[[10,0],[14,0,\"flex flex-col min-h-screen overflow-hidden\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,null],[1,\"\\n    \"],[10,\"main\"],[14,0,\"grow\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"relative max-w-6xl mx-auto h-0 pointer-events-none\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n            \"],[8,[39,1],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],null,null,null],[1,\"\\n\"],[13]],[],false,[\"open/header\",\"open/page-illustration\",\"component\",\"-outlet\",\"open/footer\"]]",
    "moduleName": "@fleetbase/frontend/templates/portal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});