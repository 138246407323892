define("@fleetbase/frontend/utils/is-function", ["exports", "@fleetbase/ui/utils/is-function"], function (_exports, _isFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isFunction.default;
    }
  });
});