define("@fleetbase/frontend/models/role", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RoleModel extends _model.default {}

  _exports.default = RoleModel;
});