define("@fleetbase/frontend/controllers/docs/api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DocsApiController extends Ember.Controller {}

  _exports.default = DocsApiController;
});