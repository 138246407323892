define("@fleetbase/frontend/utils/transform-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformQueryParams;

  // Transforms query-params template helper to what transitonTo expects
  function transformQueryParams(transitionArgs) {
    return !transitionArgs || typeof transitionArgs.map !== 'function' ? transitionArgs : transitionArgs.map(arg => {
      if (arg && arg.isQueryParams) {
        return {
          queryParams: arg.values
        };
      } else {
        return arg;
      }
    });
  }
});